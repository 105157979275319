import React, { useEffect, useState } from 'react'
import CustomBreadcrumbs from '../../ui/BreadCrumbs'
import secureApiFetch from '../../services/secureFetchApi'
import Folder from '../../ui/Folder'
import { useNavigate, useParams } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Email from '@mui/icons-material/Email';
import Lock from '@mui/icons-material/Lock';
import Work from '@mui/icons-material/Work';
import Group from '@mui/icons-material/Group';
import toast from 'react-hot-toast'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material'

const EditUser = () => {
    const { id } = useParams()
    const [formData, setFormData] = useState({
        "username": "",
        "email": "",
        "designation": "",
        "user_role": "",
        "password": "",
        "confirm_password": ""
    });
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate()
    const getUserDetails = () => {
        secureApiFetch(`/api/v1/user/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setFormData(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formData.password || formData.confirm_password) {
            if (formData.password !== formData.confirm_password) {
                toast.error("Passwords do not match");
                return;
            }
        }
        const dataToSubmit = { ...formData };
        if (!formData.password && !formData.confirm_password) {
            delete dataToSubmit.password;
            delete dataToSubmit.confirm_password;
        }

        secureApiFetch(`/api/v1/user/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(dataToSubmit)
        })
            .then(response => {
                if (response.ok) {
                    navigate('/users');
                    toast.success("User edited successfully");
                } else {
                    throw new Error("Failed to edit user");
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        getUserDetails()
    }, [])
    return (
        <div>
            <div className='flex flex-col pb-3'>
                <CustomBreadcrumbs backUrl="/" backLabel="User Edit" />
                <Folder label='Edit User Profile' />
            </div>
            <hr className='mb-6' />
            <form onSubmit={handleSubmit}>
                <div></div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mx-2">
                    <div className="space-y-4">
                        <TextField
                            fullWidth
                            label="Username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Email />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Designation"
                            name="designation"
                            value={formData.designation}
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Work />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className="space-y-4">
                        <FormControl fullWidth>
                            <InputLabel>User Role</InputLabel>
                            <Select
                                label="User Role"
                                name="user_role"
                                value={formData.user_role}
                                onChange={handleChange}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Group />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem value="admin">Admin</MenuItem>
                                <MenuItem value="user">User</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            label="New Password"
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            value={formData.password}
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Confirm Password"
                            name="confirm_password"
                            type="password"
                            value={formData.confirm_password}
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </div>

                <div className="fixed bottom-6 right-10">
                    <Button variant="contained" color="customColor" type="submit">Submit</Button>
                </div>
            </form>
        </div>
    )
}

export default EditUser
