import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CustomBreadcrumbs = ({ backUrl, backLabel, currentLabel }) => {
    const handleBack = () => {
        window.history.back();
    };

    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {backUrl && (
                <Link color="textPrimary" onClick={handleBack}>
                    <ArrowBackIcon fontSize="small" />
                </Link>
            )}
            {backLabel && (
                <Link sx={{ fontSize: '13px' }} color="textPrimary" onClick={handleBack}>
                    {backLabel}
                </Link>
            )}
            {currentLabel && <Typography sx={{ fontSize: '13px' }} color="textPrimary">{currentLabel}</Typography>}
        </Breadcrumbs>
    );
};

export default CustomBreadcrumbs;
