import React, { useEffect, useState } from 'react'
import secureApiFetch from '../../../services/secureFetchApi'
import { useSelectedWorkspace } from '../../context/SelectedWorkspaceContext ';
import { Link } from 'react-router-dom';
import TableLoaderDashboard from '../../../ui/TableLoaderDashboard';

const RTask = () => {
    const [taskData, setTaskData] = useState([])
    const [loading, setLoading] = useState(false)
    const { selectedWorkspaceObject } = useSelectedWorkspace()
    const getTask = () => {
        setLoading(true)
        secureApiFetch(`/api/v1/task/latest_updated/workspace/${selectedWorkspaceObject.workspace_id}?limit=10`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((resp) => resp.json())
            .then((data) => {
                const sortedData = data.sort((a, b) => a.task_priority - b.task_priority);
                setTaskData(sortedData);
            })
            .catch((error) => {
                console.log('Error Fetching Data', error)
            })
            .finally(() => {
                setLoading(false);
            });

    }

    useEffect(() => {
        if (selectedWorkspaceObject) {
            getTask()
        }
    }, [selectedWorkspaceObject])


    return (
        <div className="border border-gray-200 relative overflow-x-auto rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>
                        <th scope="col" className="px-2 py-2">Title</th>
                        <th scope="col" className="px-2 py-2">Status</th>
                        <th scope="col" className="px-2 py-2 text-center">Task Priority</th>
                        <th scope="col" className="px-2 py-2">Assignee</th>
                    </tr>
                </thead>
                <tbody>
                    {loading && (<tr>
                        <td colSpan={4} className="py-2 text-center font-bold text-base text-red-600">
                            <TableLoaderDashboard />
                        </td>
                    </tr>)
                    }
                    {!loading && taskData.length === 0 && (
                        <tr>
                            <td colSpan={4} className="py-2 text-center font-bold text-base text-red-600">
                                No data available
                            </td>
                        </tr>
                    )}
                    {!loading && taskData.length > 0 && taskData.length !== 0 && taskData.map((item, index) => (
                        <tr key={index} className="bg-white border-b border-gray-300 text-black font-medium">
                            <td className="px-2 py-2 ">
                                <Link to={`/tasks/detail/${item.task_id}`} className="px-2 py-2 cursor-pointer hover:underline">{item.title}</Link>
                            </td>
                            <td className="px-2 py-2 "> {item.status} </td>
                            <td className="px-2 py-2 text-center"> {item.task_priority} </td>
                            <td className="px-2 py-2 "> {item.assignee_name} </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default RTask