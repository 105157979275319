import React, { useEffect, useState, useCallback } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import secureApiFetch from '../services/secureFetchApi';

const ScheduleFilter = ({ totalPages, page, setTotalPages, workId, setTaskData, setCurrentPage, setLoading, assigneeNames }) => {

    const [filters, setFilters] = useState({ user: '' });

    const fetchData = useCallback(async (url) => {
        setLoading(true);
        const config = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        };
        const res = await secureApiFetch(url, config);
        if (res.headers.has('X-Page-Count')) {
            const totalPagesCount = parseInt(res.headers.get('X-Page-Count'));
            setTotalPages(totalPagesCount);
        }
        const data = await res.json();
        setTaskData(data);
        setLoading(false);
    }, [setLoading, setTaskData, setTotalPages]);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        fetchTasks(newPage);
    };

    const fetchTasks = (page) => {
        const { user } = filters;
        let url = `/api/v1/schedule_task/workspace/${workId}?page=${page}`;
        if (user) url += `&assignee_id=${user}`;
        fetchData(url);
    };

    useEffect(() => {
        if (workId) {
            fetchTasks(1);
        }
    }, [filters.user, fetchData]);

    const handleFiltersChange = (event) => {
        const { name, value } = event.target;
        setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
    };

    return (
        <div className=''>
            <div className="flex justify-between">
                <div></div>
                <Stack spacing={2}>
                    <Pagination count={totalPages} page={page} onChange={handleChangePage} size="large" />
                </Stack>
                <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
                    <InputLabel>Assignee Name</InputLabel>
                    <Select
                        label='Assignee Name'
                        name="user"
                        value={filters.user}
                        onChange={handleFiltersChange}
                    >
                        <MenuItem value="">All</MenuItem>
                        {assigneeNames && assigneeNames.map((user) => (
                            <MenuItem key={user.assignee_id} value={user.assignee_id}>{user.assignee_name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    )
}

export default ScheduleFilter