import React from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import secureApiFetch from "../../services/secureFetchApi";
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-hot-toast';
import TableLoader from '../../ui/TableLoader'
import AvatarApp from "../avatar/AvatarApp";
import ColorBadge from "../../ui/ColorBadge";
import { Link } from "react-router-dom";

const UserTable = ({ allUsers, getData }) => {

    const handleDelete = (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this record?");
        if (confirmed) {
            secureApiFetch(`/api/v1/user/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            })
                .then(response => {
                    if (response.ok) {
                        toast.success("User Delete Successfully");
                        getData();
                    } else {
                        throw new Error("Failed to add user");
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
        }

    }

    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="bg-[#3D3A89]">
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Id</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">UserName</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Email</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">User Role</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Designation</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allUsers.length === 0 && (<TableRow>
                            <TableCell colSpan={6}><TableLoader /></TableCell>
                        </TableRow>)}
                        {allUsers.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell align="left">{item.user_id}</TableCell>
                                <TableCell align="left">
                                    <span className="flex items-center space-x-2">
                                        <AvatarApp email={item.email} size="35px" />
                                        <span className="font-bold">{item.username}</span>
                                    </span>
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="left">{item.email}</TableCell>
                                <TableCell align="left">
                                    <ColorBadge badge={item.user_role} />
                                </TableCell>
                                <TableCell align="left">{item.designation}</TableCell>
                                <TableCell align="left">
                                    <IconButton onClick={() => handleDelete(item.user_id)}>
                                        <DeleteIcon className="text-red-700" fontSize="small" />
                                    </IconButton>
                                    <Link to={`/users/edit/${item.user_id}`}>
                                        <EditIcon className="text-[#3D3A89] " fontSize="small" />
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
export default UserTable