import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Grid, Typography, IconButton } from '@mui/material';
import secureApiFetch from '../../services/secureFetchApi';
import CloseIcon from '@mui/icons-material/Close';

const WorkspaceEdit = ({ workspaceId, onClose, TableData }) => {
    const [formData, setFormData] = useState([]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const getData = () => {
        secureApiFetch(`/api/v1/workspace/${workspaceId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setFormData(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        secureApiFetch(`/api/v1/workspace/${workspaceId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then((resp) => {
                getData();
            });
        onClose()
        TableData()
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: '1rem' }}>
                <Grid item>
                    <Typography variant="h6" gutterBottom>Edit Workspace Form</Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Container maxWidth="sm">
                <div className='mt-3'>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    inputProps={{ maxLength: 30 }}
                                    name="workspace_name"
                                    value={formData.workspace_name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="customColor" >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>

        </div>
    );
};

export default WorkspaceEdit;
