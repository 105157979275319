import RTask from "./dashboard list/RTask";
import TaskCalender from "./dashboard list/TaskCalender";
import TaskDate from "./dashboard list/WorkloadbyDay";
import WorkSpaceTaskStatus from "./dashboard list/WorkSpaceTaskStatus";

const DashLink = {
    'StatusCount': {
        title: 'Workspace Task Status',
        visible: true,
        space: 2,
        component: <WorkSpaceTaskStatus />
    },
    'RTask': {
        title: 'Recent Task in Workspace',
        visible: true,
        space: 2,
        component: <RTask />
    },
    'TaskDate': {
        title: 'Workload By Day',
        visible: true,
        space: 4,
        component: <TaskDate />
    },
    'Task Calender': {
        title: 'Workload By Calendar',
        visible: true,
        space: 4,
        component: <TaskCalender />
    },

};

export default DashLink;
