import React, { createContext, useContext, useState } from 'react';

const SelectedWorkspaceContext = createContext();

export const useSelectedWorkspace = () => useContext(SelectedWorkspaceContext);

export const SelectedWorkspaceProvider = ({ children }) => {
  const [selectedWorkspaceObject, setSelectedWorkspaceObject] = useState({});

  return (
    <SelectedWorkspaceContext.Provider value={{ selectedWorkspaceObject, setSelectedWorkspaceObject }}>
      {children}
    </SelectedWorkspaceContext.Provider>
  );
};
