import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Auth from '../../services/auth';

import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

function Sidebar({ isSidebarOpen }) {
  const location = useLocation();
  const user = Auth.getLoggedInUser();

  const isActive = (path) => location.pathname === path;

  return (
    <div className={`h-[85vh] w-[15rem] sticky top-[4.4rem] bg-white my-2 mr-1 rounded-md shadow-lg ${isSidebarOpen ? '' : 'hidden'}`}>
      <div className='flex flex-col justify-between '>

        <NavItem to="/" icon={<DashboardIcon fontSize='small' />} text="Dashboard" isActive={isActive} />

        <div>
          <label className='px-2 text-gray-500 text-xs font-semibold'>Tasks</label>
          <div>
            <NavItem to="/tasks" icon={<AssignmentOutlinedIcon fontSize='small' />} text="Tasks" isActive={isActive} />
            <NavItem to="/scheduletask" icon={<DateRangeIcon fontSize='small' />} text="Schedule Task List" isActive={isActive} />
            <NavItem to="/workspaces" icon={<WorkspacesIcon fontSize='small' />} text="Workspaces" isActive={isActive} />
          </div>
        </div>

        <div>
          {user && user.user_role === 'admin' &&
            <div>
              <label className='px-2 text-gray-500 text-xs font-semibold'>Administrator</label>
              <>
                <NavItem to="/users" icon={<PeopleAltIcon fontSize='small' />} text="Users" isActive={isActive} />
                {/* <NavItem to="/setting" icon={<SettingsIcon fontSize='small' />} text="Settings" isActive={isActive} /> */}
                <NavItem to="/report" icon={<MenuBookOutlinedIcon fontSize='small' />} text="Report" isActive={isActive} />
              </>
            </div>
          }
        </div>

      </div>
    </div>
  );
}

const NavItem = ({ to, icon, text, isActive }) => (
  <div className="my-2 mx-1">
    <Link
      to={to}
      className={`flex items-center rounded-lg px-3 py-2 text-xs font-semibold hover:text-[#EB1F36] ${isActive(to)
        ? 'text-[#EB1F36]'
        : 'text-gray-800'
        }`}
    >
      <span className="mr-2">{icon}</span> {text}
    </Link>
  </div>
);


export default Sidebar;
