import React, { useEffect, useState } from 'react'
import { useSelectedWorkspace } from '../context/SelectedWorkspaceContext ';
import { LoadingSpinnerOpacity } from '../common/LoadingSpinner';
import { toast } from 'react-hot-toast';
import { Autocomplete, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

import ReactQuill from 'react-quill';
import secureApiFetch from '../../services/secureFetchApi';
import 'react-quill/dist/quill.snow.css';

function AddTaskForm({ workspacePermissionsData }) {
    const { selectedWorkspaceObject } = useSelectedWorkspace()

    const [formDataAddTask, setformDataAddTask] = useState({
        title: '',
        description: '',
        workspace_id: selectedWorkspaceObject.workspace_id,
        assignee_id: '',
        start_date: '',
        end_date: '',
        status: 'todo',
        task_type: '',
        task_priority: 3,
        dependent_on_id: '',
        task_importance: ''
    });

    useEffect(() => {
        setformDataAddTask(prevState => ({
            ...prevState,
            workspace_id: selectedWorkspaceObject.workspace_id
        }));
    }, [selectedWorkspaceObject.workspace_id]);

    const navigate = useNavigate()
    const [isWorkspaceSelected, setIsWorkspaceSelected] = useState(false);
    const [workspaceUsers, setWorkspaceUsers] = useState([]);
    const [workspaceTasks, setWorkspaceTasks] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchWorkspaceUsers = async () => {
            try {
                if (formDataAddTask.workspace_id || selectedWorkspaceObject.workspace_id) {
                    setLoading(true)
                    const userResponse = await secureApiFetch(`/api/v1/workspace_permissions/${formDataAddTask.workspace_id || selectedWorkspaceObject.workspace_id}/users`,
                        {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            }
                        }
                    )
                    const data = await userResponse.json();
                    setWorkspaceUsers(data)
                }
            } catch (error) {
                console.error('Error fetching WorkspaceUsers:', error);
            } finally {
                setLoading(false)
            }
        };

        fetchWorkspaceUsers();
    }, [formDataAddTask.workspace_id, selectedWorkspaceObject.workspace_id]);

    useEffect(() => {
        if (formDataAddTask.workspace_id) {
            const fetchTaskData = async (workspaceId) => {
                setLoading(true)
                try {
                    const taskResponse = await secureApiFetch(`/api/v1/task/workspace/${formDataAddTask.workspace_id}`,
                        {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            }
                        }
                    )
                    const taskResponseData = await taskResponse.json();
                    setWorkspaceTasks(taskResponseData)
                } catch (error) {
                    console.error('Error while fetching tasks:', error);
                } finally {
                    setLoading(false)
                }
            };
            fetchTaskData()
        };
    }, [formDataAddTask.workspace_id]);

    useEffect(() => {
        if (selectedWorkspaceObject) {
            setIsWorkspaceSelected(!!selectedWorkspaceObject.workspace_id);
        }
    }, [selectedWorkspaceObject])

    const handleAddTaskChange = (e) => {
        const { name, value } = e.target;
        if (name === 'workspace_id') {
            setIsWorkspaceSelected(!!value);
        }
        setformDataAddTask(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleDependentHold = (event, selectedOption) => {
        if (selectedOption) {
            const taskNameId = selectedOption.task_id;
            setformDataAddTask(prevState => ({
                ...prevState,
                dependent_on_id: taskNameId
            }));
        }
    };

    const handleDescription = (value) => {
        setformDataAddTask((prev) => ({
            ...prev,
            'description': value
        }))
    }

    const handleSubmitAddTask = async (e) => {
        e.preventDefault();
        const updatedFormData = { ...formDataAddTask };

        if (updatedFormData.dependent_on_id === '') {
            delete updatedFormData.dependent_on_id;
        }
        try {
            setLoading(true)
            const createTaskResponse = await secureApiFetch('/api/v1/task',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify(updatedFormData)
                }
            )
            if (createTaskResponse.status === 201) {
                toast.success("Task created successfully.");
                navigate('/tasks')
                setformDataAddTask({
                    title: '',
                    description: '',
                    workspace_id: '',
                    assignee_id: '',
                    start_date: '',
                    end_date: '',
                    status: 'todo',
                    task_type: '',
                    task_priority: 'schedule',
                    dependent_on_id: ''
                });
            }else if(createTaskResponse.status === 403){
                toast.error("Sorry, you don't have permission to create task.");
            }
        } catch (error) {
            console.error('Error while creating task:', error);
            toast.error("Unable to create task.");
        } finally {
            setLoading(false)
        }
    };

    const marks = [
        { value: 0, label: 'High 0' },
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5 Low' },
    ];
    const getSliderColor = (value) => {
        if (value === 0) return '#FF0000 ';
        if (value === 1) return '#FF6347';
        if (value === 2) return '#FFA07A ';
        if (value === 3) return '#94C973 ';
        if (value === 4) return '#006400 ';
        if (value === 5) return '#556B2F';
        return 'default';
    };

    return (
        <div>
            {(!(workspacePermissionsData) || loading) && <LoadingSpinnerOpacity />}
            {workspacePermissionsData && (
                <form onSubmit={handleSubmitAddTask}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        {/* ----------------------------------left side---------------------------------- */}
                        <div className="space-y-4">
                            <TextField
                                name="title"
                                label="Title"
                                inputProps={{ maxLength: 60 }}
                                value={formDataAddTask.title}
                                onChange={handleAddTaskChange}
                                fullWidth
                                required
                                className="w-full"
                            />
                            <TextField
                                name="task_type"
                                label="Task Type"
                                value={formDataAddTask.task_type}
                                onChange={handleAddTaskChange}
                                fullWidth
                                className="w-full"
                            />
                            <ReactQuill
                                theme="snow"
                                name="description"
                                value={formDataAddTask.description}
                                onChange={handleDescription}
                                className='h-40'
                            />

                        </div>
                        {/* ---------------------------------------right side--------------------------------------- */}
                        <div className="space-y-4">
                            <div className="grid grid-cols-2 gap-4">
                                <TextField
                                    name="start_date"
                                    label="Start Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    value={formDataAddTask.start_date}
                                    onChange={handleAddTaskChange}
                                    fullWidth
                                    required
                                    className="w-full"
                                />
                                <TextField
                                    name="end_date"
                                    label="Deadline"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    value={formDataAddTask.end_date}
                                    onChange={handleAddTaskChange}
                                    fullWidth
                                    required
                                    className="w-full"
                                />

                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <FormControl fullWidth>
                                    <InputLabel>Workspace</InputLabel>
                                    <Select
                                        name="workspace_id"
                                        value={formDataAddTask.workspace_id || selectedWorkspaceObject.workspace_id}
                                        onChange={handleAddTaskChange}
                                        input={<OutlinedInput label="Workspace" />}
                                        required
                                        className="w-full"
                                    >
                                        {workspacePermissionsData.map((workspace) => (
                                            <MenuItem key={workspace.id} value={workspace.workspace_id}>
                                                {workspace.workspace_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel>Assign To</InputLabel>
                                    <Select
                                        name="assignee_id"
                                        value={formDataAddTask.assignee_id}
                                        onChange={handleAddTaskChange}
                                        input={<OutlinedInput label="Assign To" />}
                                        required
                                        disabled={!isWorkspaceSelected}
                                        className="w-full"
                                    >
                                        {workspaceUsers.map((userData) => (
                                            <MenuItem key={userData.id} value={userData.user_id}>
                                                {userData.username}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <FormControl fullWidth>
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        name="status"
                                        value={formDataAddTask.status}
                                        onChange={handleAddTaskChange}
                                        input={<OutlinedInput label="Status" />}
                                        className="w-full"
                                    >
                                        <MenuItem value="planned">Planned</MenuItem>
                                        <MenuItem value="on_hold">On Hold</MenuItem>
                                        <MenuItem value="dependent_hold">Dependent Hold</MenuItem>
                                        <MenuItem value="todo">To Do</MenuItem>
                                        <MenuItem value="inprogress">In Progress</MenuItem>
                                        <MenuItem value="completed">Completed</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel>Task Importance</InputLabel>
                                    <Select
                                        name="task_importance"
                                        value={formDataAddTask.task_importance}
                                        onChange={handleAddTaskChange}
                                        input={<OutlinedInput label="Task Importance" />}
                                        className="w-full"
                                    >
                                        <MenuItem value="do_first">Do First</MenuItem>
                                        <MenuItem value="schedule">Schedule</MenuItem>
                                        <MenuItem value="delegate">Delegate</MenuItem>
                                        <MenuItem value="eliminate">Eliminate</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <Box sx={{ width: 200, marginLeft: 2 }}>
                                    Task Priority
                                    <Slider
                                        name="task_priority"
                                        aria-label="Task Priority"
                                        value={formDataAddTask.task_priority}
                                        step={1}
                                        onChange={handleAddTaskChange}
                                        min={0}
                                        max={5}
                                        marks={marks}
                                        valueLabelDisplay="off"
                                        color="customColor"
                                        label={true}
                                        sx={{
                                            '& .MuiSlider-thumb': {
                                                color: getSliderColor(formDataAddTask.task_priority),
                                            },
                                            '& .MuiSlider-track': {
                                                color: getSliderColor(formDataAddTask.task_priority),
                                            },
                                            '& .MuiSlider-rail': {
                                                color: getSliderColor(formDataAddTask.task_priority),
                                            },
                                        }}
                                    />
                                </Box> 
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        disablePortal
                                        options={workspaceTasks}
                                        onChange={handleDependentHold}
                                        getOptionLabel={(workspace) => workspace.title}
                                        renderInput={(params) => <TextField {...params} label="Select Dependent Task" />}
                                    />

                                </FormControl>
                            </div>
                            <div className="fixed bottom-6 right-10">
                                <Button type="submit" variant="contained" color="customColor" size="small" className="mt-4">
                                    Create Task
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </div>
    )
}

export default AddTaskForm
