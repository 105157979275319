import React, { useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Grid, Typography, IconButton, FormControlLabel, } from '@mui/material';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';

import secureApiFetch from '../../services/secureFetchApi';

const TaskEdit = ({ editId, onClose, taskData }) => {
    const [task, setTask] = useState({});
    const [changedFields, setChangedFields] = useState({});
    const [workspaceUsers, setWorkspaceUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const getData = () => {
        secureApiFetch(`/api/v1/task/${editId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setTask(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const getWorkspaceUsers = async () => {
        try {
            const userResponse = await secureApiFetch(`/api/v1/task/workspace/${task.workspace_id}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
            const data = await userResponse.json();
            setWorkspaceUsers(data)
            console.log(data)
        } catch (error) {
            console.error('Error fetching WorkspaceUsers:', error);
        } finally {
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setTask({ ...task, [name]: newValue });
        setChangedFields({ ...changedFields, [name]: newValue });
    };

    const handleDependentHold = (event, selectedOption) => {
        if (selectedOption) {
            const taskNameId = selectedOption.task_id;
            alert(taskNameId)
            setTask(prevState => ({ ...prevState, "dependent_on_id": taskNameId }));
            setChangedFields(prevState => ({ ...prevState, "dependent_on_id": taskNameId }));
        }
    };

    const handleSliderChange = (e, newValue) => {
        const updatedTask = { ...task, task_priority: newValue };
        setTask(updatedTask);
        setChangedFields({ ...changedFields, task_priority: newValue });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        secureApiFetch(`/api/v1/task/${editId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(changedFields)
        })
            .then(response => {
                if (response.ok) {
                    toast.success("Task Updated successfully");
                    taskData()
                    setTimeout(() => {
                        onClose();
                    }, 1000);

                } else {
                    throw new Error("Failed to update task");
                }
            })
            .catch(error => {
                toast.error(error.message);
            })
            .finally(() => {
                setTimeout(() => {
                    setSubmitDisabled(false);
                }, 2000);
            });
    };

    useEffect(() => {
        if (editId) {
            getData();
        }
    }, [editId]);

    useEffect(() => {
        if (task.workspace_id) {
            getWorkspaceUsers();
        }
    }, [task.workspace_id]);

    const marks = [
        { value: 5, label: '5 Low' },
        { value: 4, label: '4' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 1, label: '1' },
        { value: 0, label: 'High 0' },
    ];

    const getSliderColor = (value) => {
        if (value === 5) return '#556B2F';
        if (value === 1) return '#FF6347';
        if (value === 2) return '#FFA07A';
        if (value === 3) return '#94C973';
        if (value === 4) return '#006400';
        if (value === 0) return '#FF0000';
        return 'default';
    };

    return (
        <div>
            <Toaster position="top-center" reverseOrder={false} />
            <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: '8px' }}>
                <Grid item>
                    <Typography variant="h6" gutterBottom>Edit Task</Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <form onSubmit={handleSubmit} className="px-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">

                    <div className="space-y-4">
                        <TextField
                            fullWidth
                            label="Task type"
                            variant="outlined"
                            name='task_type'
                            value={task.task_type || ''}
                            onChange={handleChange}
                        />
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Task Importance</InputLabel>
                            <Select value={task.task_importance || ''} onChange={handleChange} label="Task Importance" name='task_importance'>
                                <MenuItem value="do_first">Do First</MenuItem>
                                <MenuItem value="schedule">Schedule</MenuItem>
                                <MenuItem value="delegate">Delegate</MenuItem>
                                <MenuItem value="eliminate">Eliminate</MenuItem>
                            </Select>
                        </FormControl>
                        <Autocomplete
                            id="combo-box-demo"
                            disablePortal
                            options={workspaceUsers}
                            onChange={handleDependentHold}
                            getOptionLabel={(workspace) => workspace.title}
                            renderInput={(params) => <TextField {...params} label="Dependent Task" />}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="is_archived"
                                    checked={task.is_archived || false}
                                    onChange={handleChange}
                                    color="customColor"
                                />
                            }
                            label="Is Archived"
                            className="block text-gray-700 text-sm font-bold mb-2"
                        />
                    </div>

                    <div className="space-y-4">
                        <div className="mb-4">
                            <label htmlFor="start_date" className="block text-gray-700 text-sm font-bold mb-2">Start Date</label>
                            <input type="datetime-local" name="start_date" onChange={handleChange} value={task.start_date || ''} max={task.end_date || ''} className="shadow appearance-none border rounded w-72 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="end_date" className="block text-gray-700 text-sm font-bold mb-2">End Date</label>
                            <input type="datetime-local" name="end_date" onChange={handleChange} value={task.end_date || ''} min={task.start_date || ''} className="shadow appearance-none border rounded w-72 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                        </div>
                        <div className="mb-4 ml-2">
                            <label htmlFor="task_priority" className="block text-gray-700 text-sm font-bold mb-2">Task Priority</label>
                            <Box sx={{ width: 280 }}>
                                <Slider
                                    name="task_priority"
                                    aria-label="Task Priority"
                                    value={task.task_priority || 0}
                                    step={1}
                                    onChange={handleSliderChange}
                                    min={0}
                                    max={5}
                                    marks={marks}
                                    valueLabelDisplay="off"
                                    color="customColor"
                                    label={true}
                                    sx={{
                                        '& .MuiSlider-thumb': {
                                            color: getSliderColor(task.task_priority),
                                        },
                                        '& .MuiSlider-track': {
                                            color: getSliderColor(task.task_priority),
                                        },
                                        '& .MuiSlider-rail': {
                                            color: getSliderColor(task.task_priority),
                                        },
                                    }}
                                />
                            </Box>
                        </div>
                    </div>

                </div>
                <Button variant="contained" color="customColor" type="submit" disabled={submitDisabled}>Submit</Button>
            </form>
        </div>
    );
};

export default TaskEdit;
