// Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import LoadingSpinner from '../common/LoadingSpinner';
import secureApiFetch from '../../services/secureFetchApi';

const Login = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null)
    setLoading(true)
    await secureApiFetch('/api/v1/auth/token',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        body: new URLSearchParams({
          'grant_type': 'password',
          'username': username,
          'password': password,
          'scope': '',
          'client_id': '',
          'client_secret': ''
        })
      }
    ).then(response => {
      if (response.status === 401) {
        setError("Invalid username or password");
        return;
      }
      return response.json()
    })
      .then(loginData => {
        if (!loginData || !loginData.access_token) {
          console.error("Invalid username or password");
          return;
        }
        localStorage.setItem('token', loginData.access_token);
        setLoading(false)
        navigate('/')
      })
  };


  return (
    <section>
      {loading && <LoadingSpinner />}
      <div className="flex items-center justify-center px-4 py-10 sm:px-6 sm:py-16 lg:px-8 lg:py-20">
        <div className="mx-auto w-full max-w-sm md:max-w-md lg:max-w-md xl:max-w-md 2xl:max-w-md p-10 bg-gray-50 shadow-blue-200 shadow-xl">
          <div className="mb-5 p-5 flex justify-center">
            <img src={logo} alt="Logo" style={{ width: "50%" }} />
          </div>
          <div>
            <h2 className="text-center text-2xl font-bold leading-tight text-black">
              Sign in to your account
            </h2>

            <form onSubmit={handleLogin} className="mt-8">
              <div className="space-y-5">
                <div>
                  <label htmlFor="username" className="text-base font-medium text-gray-900">

                    Username
                  </label>
                  <div className="mt-2">
                    <input
                      className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                      type="text"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div>
                  <div className="flex items-center justify-between">
                    <label htmlFor="password" className="text-base font-medium text-gray-900">

                      Password
                    </label>
                  </div>
                  <div className="mt-2">
                    <input
                      className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>
                {error && <div>
                  <p>Invalid username or password.</p>
                </div>}
                <div>
                  <button
                    type="submit"
                    className="inline-flex w-full items-center justify-center rounded-md px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-black/80"
                    style={{ backgroundColor: "#282560" }}
                  >
                    Log In{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="ml-2"
                    >
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {loading && <LoadingSpinner />}
      </div>
    </section >
  );
};

export default Login;
