import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const PaginationTable = ({ totalPages, page, changePage }) => {

    return (
        <Stack spacing={2}>
            <Pagination count={totalPages} page={page} onChange={changePage} size="large" />
        </Stack>
    );
}

export default PaginationTable;