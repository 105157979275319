import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/login/Login'
import Settings from './components/setting/list';
import Index from './components/index/Index';
import Dashboard from './components/dashboard/Dashboard';
import Profile from './components/profile/Profile';
import { ProtectedRoute } from './components/login/ProtectedRoute';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/theme';
import WorkspaceRoutes from './components/workspaces/Routes';
import TaskListRoutes from './components/taskList/Routes';
import UserRoutes from './components/users/Routes';
import SubmenuRoutes from './components/taskList/submenu/Routes';
import PageNotFound from './components/login/PageNotFound';
import ReportRoutes from './components/report/Routes';
import NotificationList from './components/appbar/notification/list';

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute Component={Index} />} >
          <Route path="" element={<Dashboard />} />
          <Route path="setting" element={<Settings />} />
          <Route path="profile" element={<Profile />} />
          <Route path="notfication" element={<NotificationList />} />
          {
            [
              ...WorkspaceRoutes,
              ...TaskListRoutes,
              ...UserRoutes,
              ...SubmenuRoutes,
              ...ReportRoutes
            ]
              .map((value, index) => React.cloneElement(value, { key: `protected_route_${index}` }))
          }
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export default App;
