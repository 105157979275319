import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SubmenuTable from './table'
import secureApiFetch from '../../../services/secureFetchApi';
import CustomBreadcrumbs from '../../../ui/BreadCrumbs';
import Folder from '../../../ui/Folder';

const SubmenuList = () => {
    const [workspace, setWorkspace] = useState([])
    const [tableId, setTableId] = useState('')

    const handleChange = (event) => {
        const id = event.target.value
        setTableId(id);
    };

    const getWorkSpace = () => {
        secureApiFetch('/api/v1/workspace', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setWorkspace(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        getWorkSpace()
    }, [])
    return (
        <div>
            <div className='flex flex-col pb-3'>
                <CustomBreadcrumbs backUrl="/" backLabel="Home" />
                <Folder label={'Schedule Task List'} />
            </div>
            <div className='my-2'>
                <SubmenuTable tableId={tableId} />
            </div>
        </div>
    )
}

export default SubmenuList