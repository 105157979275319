import React, { useEffect, useState } from 'react'
import Comments from './comments/Comments';
import secureApiFetch from '../../services/secureFetchApi';
import toast from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { useParams } from 'react-router-dom';
import DateFormat from '../../ui/DateFormat';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const DetailsTab = ({ taskDetail, getData }) => {
    const [edit, setEdit] = useState(false);
    const [editDescription, setEditDescription] = useState(false);
    const { taskid } = useParams()
    const [user, setUser] = useState([])
    const [changeDesc, setChangeDesc] = useState('')
    const [expanded, setExpanded] = useState(false);

    const getUser = () => {
        if (taskDetail.workspace_id) {
            secureApiFetch(`/api/v1/workspace_permissions/${taskDetail.workspace_id}/users`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then((res) => {
                    return res.json()
                })
                .then((data) => {
                    setUser(data)
                })
                .catch((error) => {
                    console.log('Fetch error:', 'Failed to fetch data')
                    toast.error('An error occurred while fetching data')
                })
        }
    }

    const handleChangeUser = (event) => {
        const userValue = event.target.value
        const addTask = ({
            "assignee_id": userValue,
        })
        secureApiFetch(`/api/v1/task/${taskDetail.task_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(addTask)
        })
            .then(response => {
                if (response.ok) {
                    getData()
                    toast.success("Task User Updated successfully");
                } else {
                    throw new Error("Failed to update task User");
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
        setEdit(!edit)
    };
    const handleDescription = (value) => {
        setChangeDesc(value)

    }

    const handleSubmitTitle = (event, nameValue, value, mainValue, setValue) => {
        event.preventDefault()
        const changeTitleName = ({
            [nameValue]: value,
        })
        console.log(nameValue, '', value)
        secureApiFetch(`/api/v1/task/${taskDetail.task_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(changeTitleName)
        })
            .then(response => {
                if (response.ok) {
                    getData()
                    toast.success("Task User Updated successfully");
                } else {
                    throw new Error("Failed to update task User");
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
        setValue(!mainValue)
    }

    useEffect(() => {
        if (taskid) {
            getData()
        }
    }, [])

    useEffect(() => {
        getUser()
    }, [taskDetail.workspace_id])

    return (
        <div>
            <div className="flex bg-[#f7f7f7]  rounded shadow-inner border -mt-1 py-2">
                <div className="flex-1 border-r border-gray-200 ">
                    <h1 className="text-sm font-semibold p-2">Basic Information</h1>
                    <dl className="px-4 py-1 text-sm">
                        <dt className="font-semibold mt-1 text-sm">Description
                            <IconButton onClick={() => {
                                setEditDescription(!editDescription);
                                setChangeDesc(taskDetail.description);
                            }
                            }>
                                <EditIcon sx={{ fontSize: 13 }} className="text-[#3D3A89]" />
                            </IconButton>
                        </dt>
                        {!editDescription && taskDetail.description && (
                            <div>
                                <ReactQuill
                                    theme="snow"
                                    value={expanded ? taskDetail.description : taskDetail.description.substring(0, 1500) || ''}
                                    readOnly={true}
                                    modules={{ toolbar: null }}
                                    className="border-none"
                                />
                                {taskDetail.description.length > 1500 && (
                                    <button onClick={() => setExpanded(!expanded)} className="border-black w-8 font-medium py-0">
                                        {expanded ? 'Less' : 'More'}...
                                    </button>
                                )}
                            </div>
                        )}
                        {editDescription && (
                            <div className="flex flex-col">
                                <div className='max-h-96 overflow-auto'>
                                    <ReactQuill
                                        theme="snow"
                                        name="description"
                                        value={changeDesc}
                                        onChange={handleDescription}
                                        className="border-none "
                                    />
                                </div>
                                <button onClick={(e) => handleSubmitTitle(e, 'description', changeDesc, editDescription, setEditDescription)} className='border-black w-8  font-medium py-0'>Save</button>
                            </div>
                        )}
                    </dl>
                </div>
                <div className="flex-1">
                    <h1 className="text-sm font-semibold p-2">Additional Information</h1>
                    <dl className="px-4 py-1  text-sm">
                        <div className="flex text-sm">
                            <label className="pr-2 font-semibold"> Start Date </label> <strong>-</strong>
                            <p className="ml-2  flex space-x-3"> <DateFormat date={taskDetail.start_date} /></p>
                        </div>
                        <div className="flex mt-1 text-sm">
                            <label className="pr-2 font-semibold"> End Date &nbsp; </label> <strong>-</strong>
                            <p className="ml-2 flex">
                                <DateFormat date={taskDetail.end_date} />
                            </p>
                        </div>
                        <dt className="font-semibold mt-1">
                            Assign User
                            <span onClick={() => setEdit(!edit)}>
                                <Tooltip title="Edit User"><EditIcon sx={{ fontSize: 13 }} className='text-[#3D3A89]' /></Tooltip>
                            </span>
                        </dt>
                        <dd className="ml-2 mt-1">{taskDetail.assignee_name || 'NA'} </dd>
                        {edit && (
                            <dd className="ml-2 mt-1">
                                <select name="user" onChange={handleChangeUser} value={taskDetail.assignee_name} className="shadow border rounded min-w-44 py-1 px-3 text-gray-700 leading-tight ">
                                    <option value="">Select User</option>
                                    {user.map((item) => (
                                        <option value={item.user_id}>{item.username}</option>
                                    ))}
                                </select>
                            </dd>
                        )}
                        <dt className="font-semibold mt-2">Dependent Task Title</dt>
                        <dd className="ml-2 mt-1">{taskDetail.dependent_task_title || 'NA'}</dd>
                    </dl>
                </div>
            </div>

            <div className="my-2 flex justify-end">
                <Comments task_id={taskDetail.task_id} />
            </div>
        </div>
    )
}

export default DetailsTab