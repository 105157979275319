import React, { useEffect, useState } from 'react'
import secureApiFetch from '../../../services/secureFetchApi'
import { useSelectedWorkspace } from '../../context/SelectedWorkspaceContext ';

const UserTaskCount = () => {
    const [usertask, setUserTask] = useState({});
    const [displayUserTask, setDisplayUserTask] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const { selectedWorkspaceObject } = useSelectedWorkspace();

    const getWorkspaceCount = () => {
        setLoading(true);
        setError(false);

        secureApiFetch(`/api/v1/task/count/user?workspace_id=${selectedWorkspaceObject.workspace_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        })
            .then((resp) => resp.json())
            .then((data) => {
                if (data) {
                    setUserTask(data);
                } else {
                    setError(true);
                }
            })
            .catch((error) => {
                console.log('Error Fetching Data', error);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (selectedWorkspaceObject) {
            getWorkspaceCount();
        }
    }, [selectedWorkspaceObject]);

    useEffect(() => {
        if (usertask) {
            Object.keys(usertask).forEach((key) => {
                if (typeof usertask[key] === 'number') {
                    animateUserTaskCount(key, usertask[key]);
                }
            });
        }
    }, [usertask]);

    const animateUserTaskCount = (key, value) => {
        let start = 0;
        const duration = 2000;
        const increment = Math.ceil(value / (duration / 20));

        const updateCount = () => {
            start += increment;
            if (start >= value) {
                start = value;
                clearTimeout(timer);
            }
            setDisplayUserTask((prevState) => ({
                ...prevState,
                [key]: start,
            }));
        };

        const timer = setInterval(updateCount, 70);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error || Object.keys(usertask).length === 0) {
        return <div className="text-center text-red-500">Error fetching data or no data available.</div>;
    }

    return (
        <div className="flex flex-wrap justify-center gap-2 text-[12px]">
            <div className="bg-[#d49069] text-white rounded p-2 text-center w-[5.5rem]">
                <label className="block font-bold">{displayUserTask.planned || 0}</label>
                <label className="block font-medium">Planned</label>
            </div>
            <div className="bg-[#FFC000] text-white rounded p-2 text-center w-[5.5rem]">
                <label className="block font-bold">{displayUserTask.todo || 0}</label>
                <label className="block font-medium">To Do</label>
            </div>
            <div className="bg-[#00B050] text-white rounded px-3 py-2 text-center w-[5.5rem]">
                <label className="block font-bold">{displayUserTask.inprogress || 0}</label>
                <label className="block font-medium">In Progress</label>
            </div>
            <div className="bg-[#FF0000] text-white rounded px-3 py-2 text-center w-[5.5rem]">
                <label className="block font-bold">{displayUserTask.on_hold || 0}</label>
                <label className="block font-medium">On Hold</label>
            </div>
            <div className="bg-red-200 text-red-800 rounded px-3 py-2 text-center w-[5.5rem]">
                <label className="block font-bold">{displayUserTask.dependent_hold || 0}</label>
                <label className="block font-medium">Dependent</label>
            </div>
            <div className="bg-[#0070C0] text-white rounded px-4 py-2 text-center w-[5.5rem]">
                <label className="block font-bold">{displayUserTask.completed || 0}</label>
                <label className="block font-medium">Completed</label>
            </div>
            <div className="bg-gray-500 text-white rounded px-3 py-2 text-center w-[5.5rem]">
                <label className="block font-bold">{displayUserTask.total_count || 0}</label>
                <label className="block font-medium">Total Count</label>
            </div>
        </div>
    )
}
export default UserTaskCount