import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const TableLoaderDashboard = () => {
    return (
        <Box>
            <Skeleton animation="wave" sx={{ height: 15, marginBottom: 1 }} />
            <Skeleton animation="wave" sx={{ height: 15, marginBottom: 1 }} />
        </Box>
    )
}

export default TableLoaderDashboard