import React, { useEffect, useState } from 'react'
import secureApiFetch from '../../services/secureFetchApi';
import { Grid, FormControl, Checkbox, FormGroup, FormControlLabel, Typography, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const AddUser = ({ onClose, workspaceId,getWorkData }) => {
    const [allUser, setAllUser] = useState([])
    const [formData, setFormData] = useState({
        create: false,
        read: true,
        update: false,
        delete: false,
        user_id: '',
        workspace_id: workspaceId
    });

    const getData = () => {
        secureApiFetch('/api/v1/user', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setAllUser(data)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };
    const handleSelectedUserId = (event, selectedOption) => {
        if (selectedOption) {
            const userId = selectedOption.user_id;
            setFormData(prevState => ({
                ...prevState,
                user_id: userId
            }));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        secureApiFetch('/api/v1/workspace_permissions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                getWorkData()
            })
        onClose()
    }


    useEffect(() => {
        getData()
    }, [])
    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: '8px' }}>
                <Grid item>
                    <Typography variant="h6" gutterBottom>Add User</Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Select Username</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="combo-box-demo"
                            disablePortal
                            options={allUser}
                            getOptionLabel={(user) => user.username}
                            onChange={handleSelectedUserId}
                            renderInput={(params) => <TextField {...params} label="Select Username" />}
                        />

                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <Typography variant="h6" gutterBottom>Select Permissions</Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={formData.create} onChange={handleCheckboxChange} name="create" />}
                                label="Create"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={formData.read} onChange={handleCheckboxChange} name="read" />}
                                label="Read"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={formData.update} onChange={handleCheckboxChange} name="update" />}
                                label="Update"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={formData.delete} onChange={handleCheckboxChange} name="delete" />}
                                label="Delete"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Button variant="contained" onClick={handleSubmit} color="customColor" style={{ marginTop: '20px' }}>
                Submit
            </Button>
        </div>
    )
}

export default AddUser