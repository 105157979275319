import React, { useEffect, useState } from 'react';
import { Badge, IconButton, Popover, Button, Box } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import secureApiFetch from '../../../services/secureFetchApi';
import RelativeTime from '../../../features/RelativeTime';
import './Notification.css'
import { useNavigate } from 'react-router-dom';

const NotificationBadge = () => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);
    const [notification, setNotification] = useState([])
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const getNotification = () => {
        secureApiFetch(`/api/v1/notification?status=unread`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setNotification(data)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const onHandleRead = (id) => {
        secureApiFetch(`/api/v1/notification/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({notification_status: "read"})
        })
            .then(response => {
                getNotification()
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const handleClick = event => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)
    const handleMarkAllAsRead = () => {
        navigate('/notfication')
        handleClose()
    }

    const open = Boolean(anchorEl);
    const id = open ? 'notification-popover' : undefined;
    useEffect(() => {
        getNotification()
    }, [])

    return (
        <div className='mx-1'>
            <IconButton onClick={handleClick}>
                <Badge badgeContent={notification.length} color="customColor">
                    <NotificationsNoneIcon className="text-[#3A3D89]" />
                </Badge>
            </IconButton>
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} transformOrigin={{ vertical: 'top', horizontal: 'center', }} PaperProps={{ style: { marginLeft: '-12px', width: '350px' }, }}>
                <div className="text-start border-b py-4 px-6 text-[#1d2c48] font-semibold text-sm">Notifications</div>
                <Box className="pb-2 max-h-60 overflow-y-auto custom-scrollbar-note mr-1 pr-1">
                    {notification && notification.map((note, index) => (
                        <div key={index} className="mb-2 px-4 py-2 bg-white hover:bg-gray-50 flex items-center space-x-1">
                            <IconButton
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                                onClick={() => onHandleRead(note.id)}
                                sx={{ backgroundColor: '#edf3ff' }}
                                className={`transform ${hoveredIndex === index ? 'rotate-180' : 'rotate-0'} transition duration-700`}
                            >
                                {hoveredIndex === index
                                    ? <HighlightOffOutlinedIcon sx={{ color: '#e60f0f' }} fontSize='small' />
                                    : <InfoOutlinedIcon sx={{ color: '#3978fc' }} fontSize='small' />
                                }
                            </IconButton>
                            <div>
                                <div className='flex justify-between items-center text-[0.77rem] text-[#abb2ca]'>
                                    <label className='text-[0.80rem] text-[#1d2c48] font-medium'>{note.title}</label>
                                    <RelativeTime dateTime={note.insert_ts} />
                                </div>
                                <p className='text-[0.77rem] text-[#abb2ca]'>{note.description}.</p>
                            </div>
                        </div>
                    ))}
                </Box>
                <Box className="px-4 py-2 text-center">
                    <Button variant="text" color="customColor" disableRipple onClick={handleMarkAllAsRead}>
                        View All
                    </Button>
                </Box>
            </Popover>
        </div>
    );
};

export default NotificationBadge;
