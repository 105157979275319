import React, { useEffect, useState } from 'react'
import secureApiFetch from '../../../services/secureFetchApi'
import { useSelectedWorkspace } from '../../context/SelectedWorkspaceContext ';
import Chart from 'react-apexcharts';
import { Link, useNavigate } from 'react-router-dom';

const WorkSpaceTaskStatus = () => {
    const [workspaceCount, setWorkspaceCount] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const { selectedWorkspaceObject } = useSelectedWorkspace();
    const navigate = useNavigate();

    const getWorkspaceCount = () => {
        setLoading(true);
        setError(false);

        secureApiFetch(`/api/v1/task/count/workspace/${selectedWorkspaceObject.workspace_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        })
            .then((resp) => resp.json())
            .then((data) => {
                if (data) {
                    setWorkspaceCount(data);
                } else {
                    setError(true);
                }
            })
            .catch((error) => {
                console.log('Error Fetching Data', error);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (selectedWorkspaceObject.workspace_id) {
            getWorkspaceCount();
        }
    }, [selectedWorkspaceObject]);

    const calculateTotalCount = () => {
        let totalCount = 0;
        Object.keys(workspaceCount).forEach((key) => {
            if (typeof workspaceCount[key] === 'number' && key !== 'total_count') {
                totalCount += workspaceCount[key];
            }
        });
        return workspaceCount.total_count || totalCount;
    };

    const chartOptions = {
        chart: { 
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            },
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    const status = chartOptions.xaxis.categories[config.dataPointIndex];
                    navigate(`/tasks?task_status=${status.replace(' ', '_').toLowerCase()}`);
                }
            }
        },
        plotOptions: {
            bar: {
                distributed: true,
                horizontal: false,
                columnWidth: '40%',
                endingShape: 'rounded'
            }
        },
        dataLabels: {
            enabled: true
        },
        xaxis: {
            categories: [
                'planned',
                'todo',
                'inprogress',
                'completed',
                'on_hold',
                'dependent_hold',
            ],
            labels: {
                show:false,
                style: {
                    colors: [],
                    fontSize: '12px',
                    cssClass: 'apexcharts-xaxis-label',
                },
            }
        },
        colors: ['#d49069', '#FFC000', '#00B050', '#0070C0', '#FF0000', '#fecaca'],
        legend: {
            show: true
        },
        title: {
            text: `Total Workspace Task Count: ${calculateTotalCount()}`,
            align: 'center',
            margin: 10,
            offsetY: 20,
            style: {
                fontSize: '16px'
            }
        },
    };

    const chartSeries = [{
        name: 'Count',
        data: [
            workspaceCount.planned || 0,
            workspaceCount.todo || 0,
            workspaceCount.inprogress || 0,
            workspaceCount.completed || 0,
            workspaceCount.on_hold || 0,
            workspaceCount.dependent_hold || 0,
        ]
    }];

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error || Object.keys(workspaceCount).length === 0) {
        return <div className="text-center text-red-500">Error fetching data or no data available.</div>;
    }

    return (
        <div>
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height={350}
            />
        </div>
    );
};

export default WorkSpaceTaskStatus;