import { Route } from "react-router-dom";
import AllUsers from "./list";
import EditUser from "./editUser";
import CreateUser from "./createUser";

const UserRoutes = [
    <Route path={`/users`} element={<AllUsers />} />,
    <Route path={`/users/create`} element={<CreateUser />} />,
    <Route path={`/users/edit/:id`} element={<EditUser />} />,
]
export default UserRoutes