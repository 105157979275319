import React from 'react'

const PriorityBadge = ({badge}) => {
    let badgeColorClass = '';
    switch (badge) {
        // importance
        case 'do_first':
            badgeColorClass = 'bg-[#C00000] text-white';
            break;
        case 'schedule':
            badgeColorClass = 'bg-[#00B050] text-white';
            break;
        case 'delegate':
            badgeColorClass = 'bg-indigo-200 text-indigo-800';
            break;
        case 'eliminate':
            badgeColorClass = 'bg-[#FF0000] text-white';
            break;

        // priority 
        case 0:
            badgeColorClass = 'bg-[#FF0000] text-white';
            break;
        case 1:
            badgeColorClass = 'bg-[#FF6347] text-white';
            break;
        case 2:
            badgeColorClass = 'bg-[#FFA07A] text-white';
            break;
        case 3:
            badgeColorClass = 'bg-[#94C973] text-white';
            break;
        case 4:
            badgeColorClass = 'bg-[#006400] text-white';
            break;
        case 5:
            badgeColorClass = 'bg-[#556B2F] text-white';
            break;

        // default color
        default:
            badgeColorClass = 'bg-gray-100 text-gray-800';
    }
    const badgeContent = badge !== undefined && badge !== null ? badge : "-";
    return (
        <div>
            <span className={`text-xs me-2 px-2 py-1 rounded ${badgeColorClass}`}>
                {badgeContent}
            </span>
        </div>
    );
};

export default PriorityBadge