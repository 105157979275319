import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import Collapse from '@mui/material/Collapse';
import secureApiFetch from '../../services/secureFetchApi';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import TableLoader from '../../ui/TableLoader';

const subTable = React.memo(({ item }) => {
  const [userData, setUserData] = useState([])
  const [checkboxenable, setCheckboxEnable] = useState(false)
  const [editMode, setEditMode] = useState({});

  const handleEdit = (userId) => {
    setEditMode(prevState => ({
      ...prevState,
      [userId]: !prevState[userId]
    }));
  }

  const getData = () => {
    secureApiFetch(`/api/v1/workspace_permissions/${item.workspace_id}/users`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setUserData(data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const handleChnge = (event, propertyName, userId) => {

    const updatedUserData = userData.map(user => {
      if (user.user_id === userId) {
        return {
          ...user,
          [propertyName]: event.target.checked
        };
      }
      return user;
    });
    setUserData(updatedUserData);
  };

  const handleSubmit = (id) => {
    const userToUpdate = userData.find(user => user.permission_id === id);
    const updatedUserData = {
      create: userToUpdate.create,
      read: userToUpdate.read,
      update: userToUpdate.update,
      delete: userToUpdate.delete
    }
    secureApiFetch(`/api/v1/workspace_permissions/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(updatedUserData)
    })
      .then(response => {
        if (response.ok) {
          toast.success("User Updated successfully");
          getData();
        } else {
          throw new Error("Failed to update user");
        }
      })
      .catch(error => {
        toast.error(error.message);
      });
  }

  const handleDelete = (id) => {
    const confirm = window.confirm("Are you sure you want to delete this record?")
    if (confirm) {

      secureApiFetch(`/api/v1/workspace_permissions/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
      })
        .then(response => {
          if (response.ok) {
            toast.success("User Delete successfully");
            getData();
          } else {
            throw new Error("Failed to Delete user");
          }
        })
        .catch(error => {
          toast.error(error.message);
        });
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Collapse in={true} timeout="auto" unmountOnExit>
      <TableContainer component={Paper} className='my-2 ' sx={{ border: 'none', boxShadow: 'none' }}>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow >
              <TableCell sx={{ fontWeight: 'bold' }}>username</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>create</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Read</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Update</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Delete</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Edit</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Delete</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Update</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData.length === 0 && (<TableRow>
              <TableCell colSpan={8}><TableLoader /></TableCell>
            </TableRow>)}
            {userData.map((user) => (
              <TableRow key={item.user_id} className="">
                <TableCell>{user.username}</TableCell>
                <TableCell>
                  <Checkbox onChange={(event) => handleChnge(event, 'create', user.user_id)} checked={user.create === true} disabled={!editMode[user.workspace_id]} />
                </TableCell>
                <TableCell>
                  <Checkbox onChange={(event) => handleChnge(event, 'read', user.user_id)} checked={user.read === true} disabled={!editMode[user.workspace_id]} />
                </TableCell>
                <TableCell>
                  <Checkbox onChange={(event) => handleChnge(event, 'update', user.user_id)} checked={user.update === true} disabled={!editMode[user.workspace_id]} />
                </TableCell>
                <TableCell>
                  <Checkbox onChange={(event) => handleChnge(event, 'delete', user.user_id)} checked={user.delete === true} disabled={!editMode[user.workspace_id]} />
                </TableCell>

                <TableCell >
                  <IconButton onClick={() => handleEdit(user.workspace_id)}>
                    {editMode[user.workspace_id] ? <CancelIcon className='text-red-700' /> : <EditIcon style={{ color: '#3D3A89' }} fontSize='small' />}
                  </IconButton>
                </TableCell>
                <TableCell >
                  <IconButton onClick={() => handleDelete(user.permission_id)}>
                    <PersonRemoveIcon className='text-red-700' fontSize='small' />
                  </IconButton>
                </TableCell>
                <TableCell >
                  <IconButton onClick={() => handleSubmit(user.permission_id)} disabled={!editMode[user.workspace_id]}>
                    <CheckCircleIcon style={{ color: editMode[user.workspace_id] ? '#3D3A89' : '#CCCCCC' }} />
                  </IconButton>

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Collapse>
  )
});


export default subTable