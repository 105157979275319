import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { toast, Toaster } from 'react-hot-toast';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableContainer from '@mui/material/TableContainer';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import EditIcon from '@mui/icons-material/Edit';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import secureApiFetch from '../../services/secureFetchApi';
import TableLoader from '../../ui/TableLoader';
import DateFormat from '../../ui/DateFormat';
import WorkspaceEdit from './edit';
import SubTable from './SubTable'
import AddUser from './AddUser';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

export const WorkSpaceTable = ({ workspace, getData, loading }) => {
    const [open, setOpen] = useState(false);
    const [openUser, setOpenUser] = useState(false)
    const [workspaceId, setWorkspaceId] = useState(0);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleOpenUser = (id) => {
        setOpenUser(true);
        setWorkspaceId(id);
    };
    const handleCloseUser = () => {
        setOpenUser(false);
    };
    const handleOpen = (id) => {
        setOpen(true);
        setWorkspaceId(id);
    };


    const handleClose = () => setOpen(false);

    const handleDelete = (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this record?");
        if (confirmed) {
            secureApiFetch(`/api/v1/workspace/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            })
                .then(response => {
                    if (response.ok) {
                        toast.success("Workspace Delete successfully");
                        getData();
                    } else {
                        throw new Error("Failed to Delete Workspace");
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
        }

    };

    useEffect(() => {
        getData();
    }, []);

    const toggleDropdown = (id) => {
        setSelectedRow(prev => prev === id ? null : id);
    };

    return (
        <TableContainer className='mt-4' component={Paper}>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className='bg-[#3D3A89]'>
                    <TableRow>
                        <TableCell />
                        <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Id</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Title</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Description</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="right">Update time</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="right">Insert Time</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="center">Actions</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="center">Add User</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading && (<TableRow>
                        <TableCell colSpan={10}><TableLoader /></TableCell>
                    </TableRow>)}
                    {!loading && workspace.length === 0 && (
                        <tr>
                            <td colSpan={8} align="center " className='py-3 font-bold text-xl text-red-600'>
                                No data available
                            </td>
                        </tr>
                    )}
                    {!loading && workspace.length > 0 && workspace.length !== 0 && workspace.map((item, index) => (
                        <React.Fragment key={index}>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left">
                                    <IconButton onClick={() => toggleDropdown(item.workspace_id)}>
                                        {selectedRow === item.workspace_id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </TableCell>
                                <TableCell align="left">
                                    {item.workspace_id}
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="">
                                    {item.workspace_name || '-'}
                                </TableCell>
                                <TableCell align="left">
                                    <BootstrapTooltip title={item.description}>
                                        {item.description.substring(0, 30)}...
                                    </BootstrapTooltip >
                                </TableCell>
                                <TableCell align="right">  <DateFormat date={item.update_ts} /> </TableCell>
                                <TableCell align="right">  <DateFormat date={item.insert_ts} /> </TableCell>
                                <TableCell align="center">
                                    <button onClick={() => handleDelete(item.workspace_id)}>
                                        <DeleteIcon className='text-red-700 mr-1' fontSize='small' />
                                    </button>
                                    <button onClick={() => handleOpen(item.workspace_id)}>
                                        <EditIcon className='text-[#3D3A89]' fontSize='small' />
                                    </button>
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton onClick={() => handleOpenUser(item.workspace_id)}>
                                        <PersonAddAlt1Icon style={{ color: '#3D3A89' }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                                    <Collapse in={selectedRow === item.workspace_id} timeout="auto" unmountOnExit>
                                        <Box sx={{ margin: '0px 10rem' }}>
                                            <SubTable item={item} />
                                        </Box>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>

            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  bg-white border-2 border-black shadow-md p-4">
                        <WorkspaceEdit workspaceId={workspaceId} onClose={handleClose} TableData={getData} />
                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={openUser}
                    onClose={handleCloseUser}
                >
                    <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[40rem] h-[30rem] bg-white border-2 border-black shadow-md p-4">
                        <AddUser onClose={handleCloseUser} workspaceId={workspaceId} getWorkData={getData} />
                    </Box>
                </Modal>
            </div>

        </TableContainer>
    );
};
