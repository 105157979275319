import React, { useEffect, useState } from "react"
import { TextField, Tooltip } from '@mui/material';
import secureApiFetch from "../../services/secureFetchApi";
import TableLoader from '../../ui/TableLoader'
import TaskChartTable from "./chart for table/TaskChartTable";
import ReactToPrint from 'react-to-print';

import PrintIcon from '@mui/icons-material/Print';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';

const WSDetails = () => {
    const [workData, setWorkData] = useState([])
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }
    const formatDate = (date) => date.toISOString().split('T')[0];

    const getData = (from_data, to_data) => {
        setLoading(true)
        const url = `/api/v1/dashboard/workspaces_detail?from_date=${from_data}&to_date=${to_data}`
        secureApiFetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setWorkData(data);
                setLoading(false)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleSubmit = () => {
        if (startDate && endDate) {
            getData(startDate, endDate)
        }
        handleClose()
    }

    useEffect(() => {
        const todayDate = new Date();

        const futureDate = new Date(todayDate);
        futureDate.setDate(todayDate.getDate() + 182);

        const pastDate = new Date(todayDate);
        pastDate.setDate(todayDate.getDate() - 182);

        getData(formatDate(pastDate), formatDate(futureDate));
    }, []);
    return (
        <div className="-m-4">
            <div className='flex justify-between items-center -mb-4 mx-4'>
                <ReactToPrint
                    trigger={() => <IconButton>
                        <Tooltip title='Print this out!'>
                            <PrintIcon className='text-[#3A3D89]' />
                        </Tooltip>
                    </IconButton>
                    }
                    content={() => document.getElementById('table-to-print')}
                />
                <button onClick={handleClickOpen} className='bg-[#3A3D89] p-1 rounded text-white  text-xs'>
                    Select Custom Date Range
                </button>
            </div>
            <div id='table-to-print' className="border border-gray-200 m-8 relative overflow-x-auto shadow-md rounded ">
                <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                        <tr>
                            <th scope="col" className="px-4 py-2">WorkSpace</th>
                            <th scope="col" className="px-2 py-2">User Name</th>
                            <th scope="col" className="px-2 py-2">Task</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading && (
                            <tr>
                                <td colSpan={10}><TableLoader /></td>
                            </tr>)}
                        {!loading && workData.length === 0 && (
                            <tr>
                                <td colSpan={10} align="center " className='py-3 font-medium text-lg text-red-600'>
                                    No Data Available
                                </td>
                            </tr>
                        )}
                        {!loading && Object.keys(workData).length > 0 && Object.keys(workData).map((workspace, index) => (
                            <tr key={index} className="bg-white border-b border-gray-300 text-black font-medium">
                                <td className="px-4 py-1">{workspace}</td>
                                <td className="px-2 py-1">
                                    <ol style={{ margin: 0, paddingInlineStart: '1em' }}>
                                        {workData[workspace].users.map((user, index) => (
                                            <li key={index}>{index + 1}. {user.username}</li>
                                        ))}
                                    </ol>
                                </td>
                                <td className="px-2 py-1">
                                    <TaskChartTable tasks={workData[workspace].tasks} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Select Custom Date Range"}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                        <TextField
                            size='small'
                            label="Start Date"
                            variant="outlined"
                            margin="normal"
                            value={startDate}
                            onChange={(event) => setStartDate(event.target.value)}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                        <TextField
                            size='small'
                            label="End Date"
                            variant="outlined"
                            margin="normal"
                            value={endDate}
                            onChange={(event) => setEndDate(event.target.value)}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} variant='contained' color='customColor' autoFocus>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default WSDetails