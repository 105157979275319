import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Folder from '../../ui/Folder'
import CustomBreadcrumbs from '../../ui/BreadCrumbs'
import WSDetails from './WSDetails'
import UsrDetails from './UsrDetails';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Report = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <div className='flex flex-col '>
                <CustomBreadcrumbs backUrl="/" backLabel="Report" />
            </div>
            <div className="flex items-center">
                <Folder label={'Report'} />
            </div>
            <div>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Workspace Details" {...a11yProps(0)} />
                            <Tab label="User Details" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <WSDetails />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <UsrDetails/>
                    </CustomTabPanel>
                </Box>

            </div>

        </div>
    )
}

export default Report