import React, { useEffect, useState } from "react"
import { IconButton } from "@mui/material";
import { toast, Toaster } from 'react-hot-toast';
import { useSelectedWorkspace } from "../../context/SelectedWorkspaceContext ";
import { Link } from "react-router-dom";

import TableContainer from '@mui/material/TableContainer';
import DeleteIcon from '@mui/icons-material/Delete';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import ReactQuill from 'react-quill';

import PaginationTable from '../../../features/PaginationTable'
import secureApiFetch from "../../../services/secureFetchApi";
import ScheduleFilter from '../../../features/ScheduleFilter'
import TableLoader from "../../../ui/TableLoader";
import DateFormat from "../../../ui/DateFormat";

const SubmenuTable = ({ tableId }) => {
    const [tableData, setTableData] = useState([])
    const { selectedWorkspaceObject } = useSelectedWorkspace();
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [assigneeNames, setAssigneeNames] = useState([]);

    const getData = () => {
        setLoading(true);
        secureApiFetch(`/api/v1/schedule_task/workspace/${selectedWorkspaceObject.workspace_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(response => {
                const totalPagesCount = parseInt(response.headers.get('X-Page-Count'));
                setTotalPages(totalPagesCount);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setTableData(data)
                const names = data.map(task => ({
                    assignee_id: task.assignee_id,
                    assignee_name: task.assignee_name
                }));
                const uniqueNamesSet = new Set(names.map(JSON.stringify));
                const uniqueNames = Array.from(uniqueNamesSet).map(JSON.parse);
                setAssigneeNames(uniqueNames)
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleDelete = (id) => {
        const confirm = window.confirm('Are you sure you want to delete this record')
        if (confirm) {
            secureApiFetch(`/api/v1/schedule_task/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            })
                .then((res) => {
                    if (res.ok) {
                        getData()
                        toast.success('Schedule Task Deleted Successfully')
                    } else {
                        throw new Error('Failed to Delete Schedule Task')
                    }
                })
                .catch((error) => {
                    toast.error(error.message)
                })
        }
    }


    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        if (selectedWorkspaceObject.workspace_id) {
            getData()
        }
    }, [selectedWorkspaceObject.workspace_id])

    return (
        <div>
            <Toaster position="top-center" reverseOrder={false} />
            <ScheduleFilter
                workId={selectedWorkspaceObject.workspace_id}
                assigneeNames={assigneeNames}
                setTaskData={setTableData}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                page={currentPage}
                setCurrentPage={setCurrentPage}
                setLoading={setLoading}
            />
            <hr className="my-2" />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="bg-[#3D3A89]">
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Title</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Workspace Name</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Description</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Repetition</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Assignee Name</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Last Schedule</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && (<TableRow>
                            <TableCell colSpan={10}><TableLoader /></TableCell>
                        </TableRow>)}
                        {!loading && tableData.length === 0 && (
                            <tr>
                                <td colSpan={8} align="center " className='py-3 font-bold text-xl text-red-600'>
                                    No data available
                                </td>
                            </tr>
                        )}
                        {!loading && tableData.length > 0 && tableData.length !== 0 && tableData.map((item, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                                <TableCell sx={{ fontWeight: '600' }} align="left">{item.title}</TableCell>

                                <TableCell align="left">{item.workspace_name}</TableCell>

                                <TableCell align="left">
                                    {/* {item.description} */}
                                    <ReactQuill
                                        theme="snow"
                                        value={item.description.substring(0, 500) || ''}
                                        readOnly={true}
                                        modules={{ toolbar: null }}
                                        className="border-none"
                                    />
                                </TableCell>

                                <TableCell align="left">{item.repetition}</TableCell>

                                <TableCell align="left">{item.assignee_name}</TableCell>

                                <TableCell align="left"> <DateFormat date={item.last_scheduled_for} /> </TableCell>

                                <TableCell align="left">
                                    <Link to={`/scheduletask/edit/${item.schedule_tasks_id}`}>
                                        <EditIcon className="text-blue-800" fontSize="small" />
                                    </Link>
                                    <IconButton onClick={() => handleDelete(item.schedule_tasks_id)}>
                                        <DeleteIcon className="text-red-700" fontSize="small" />
                                    </IconButton>
                                </TableCell>

                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default SubmenuTable