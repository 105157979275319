import React, { useState, useEffect } from 'react';
import AddTaskFrom from './AddTaskForm';
import ScheduleTaskForm from './ScheduleTaskForm';
import secureApiFetch from '../../services/secureFetchApi';
import CustomBreadcrumbs from '../../ui/BreadCrumbs';
import Folder from '../../ui/Folder';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const CreateTaskList = () => {
    const [value, setValue] = React.useState(0);
    const [workspacePermissionsData, setWorkspacePermissionsData] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChangeEvent = (event, newValue) => setValue(newValue)
    const fetchPermissionData = async () => {
        try {
            setLoading(true)
            const permissionResponse = await secureApiFetch('/api/v1/workspace_permissions',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
            const data = await permissionResponse.json();
            setWorkspacePermissionsData(data)

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false)
        }
    };
    useEffect(() => {
        fetchPermissionData();
    }, []);


    return (
        <div>
            <CustomBreadcrumbs backUrl="/" backLabel="tasks" currentLabel='create' />
            <div className='flex space-x-4 items-center'>
                <Folder label={`${value === 0 ? 'Create Task':'Create Schedule Task'}`} />
            </div>
            <hr />
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChangeEvent} aria-label="basic tabs example" >
                        <Tab label="Add Task" {...a11yProps(0)} />
                        <Tab label="Schedule Task" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <AddTaskFrom workspacePermissionsData={workspacePermissionsData} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <ScheduleTaskForm workspacePermissionsData={workspacePermissionsData} />
                </CustomTabPanel>
            </Box>
        </div>
    );
}

export default CreateTaskList;