import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import PaginationTable from '../../features/PaginationTable';
import secureApiFetch from '../../services/secureFetchApi';
import CustomBreadcrumbs from '../../ui/BreadCrumbs'
import Folder from '../../ui/Folder'
import UserTable from './table'

const AllUsers = () => {
    const [allUsers, setAllUsers] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const getData = (page) => {
        secureApiFetch(`/api/v1/user?page=${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(response => {
                const totalPagesCount = parseInt(response.headers.get('X-Page-Count'));
                setTotalPages(totalPagesCount);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setAllUsers(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        getData(currentPage);
    }, [currentPage, totalPages])

    return (
        <div>
            <div className='flex justify-between items-center'>
                <div className='flex flex-col'>
                    <CustomBreadcrumbs backUrl="/" backLabel="Home" />
                    <Folder label={'User'} />
                </div>
                <Button variant="contained" color="customColor" endIcon={<PersonAddIcon />}>
                    <Link to={'/users/create'}>
                        Create User
                    </Link>
                </Button>
            </div>
            <div className='flex justify-center'>
                <PaginationTable totalPages={totalPages} page={currentPage} changePage={handleChangePage} />
            </div>
            <hr />
            <div className='my-2'>
                <UserTable allUsers={allUsers} getData={getData} />
            </div>

        </div>
    )
}

export default AllUsers;