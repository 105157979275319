import React from 'react';

const DateManager = ({ endDate }) => {
    const calculateDaysLeft = () => {
        const today = new Date();
        const end = new Date(endDate);
        const differenceInTime = end.getTime() - today.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays;
    };

    const daysLeft = calculateDaysLeft();
    const isExpired = daysLeft < 0;

    let badgeColorClass = '';
    if (daysLeft < 0) {
        badgeColorClass = 'bg-[#FF0000] text-white';
    } else if (daysLeft > 7) {
        badgeColorClass = 'bg-[#00B050] text-white';
    } else if (daysLeft > 0) {
        badgeColorClass = 'bg-[#FFC000] text-white';
    } else {
        badgeColorClass = 'bg-gray-100 text-gray-800';
    }

    if (!endDate) {
        return "NA";
    }

    return (
        <div className='font-medium text-xs'>
            {isExpired ? (
                <span className={`text-xs me-2 px-2 py-1 font-medium rounded ${badgeColorClass}`}>
                    Days Pass: {daysLeft}
                </span>
            ) : (
                <span className={`text-xs me-2 px-2 py-1 font-medium rounded ${badgeColorClass}`}>
                    Days left: {daysLeft}
                </span>
            )}
        </div>
    );
};

export default DateManager;
