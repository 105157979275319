import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CustomBreadcrumbs from '../../ui/BreadCrumbs'
import { Button } from '@mui/material';
import { WorkSpaceTable } from './table';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CreateWorkspace } from './create';
import secureApiFetch from '../../services/secureFetchApi';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Folder from '../../ui/Folder';
import PaginationTable from '../../features/PaginationTable';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
function Workspaces() {
    const [open, setOpen] = useState(false);
    const [workspace, setWorkspace] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');


    const getData = (page) => {
        setLoading(true);
        secureApiFetch(`/api/v1/workspace?${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(response => {
                const totalPagesCount = parseInt(response.headers.get('X-Page-Count'));
                setTotalPages(totalPagesCount);
                if (!response.ok) {
                    if (response.status === 403) {
                        return response.json().then(data => {
                            setErrorMessage(data.detail);
                            throw new Error('403 Forbidden');
                        });
                    }
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setWorkspace(data);
                setLoading(false);
                setErrorMessage('');
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    useEffect(() => {
        getData(currentPage)
    }, [currentPage, totalPages])

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleChangePage = (event, newPage) => setCurrentPage(newPage);

    return (
        <div>
            <div className='flex justify-between items-center'>
                <div className='flex flex-col '>
                    <CustomBreadcrumbs backUrl="/" backLabel="Home" />
                    <Folder label={'Workspace'} />
                </div>
                {!errorMessage && (
                    <PaginationTable totalPages={totalPages} page={currentPage} changePage={handleChangePage} />
                )}
                {!errorMessage && (
                    <Button variant="contained" color="customColor" endIcon={<PlaylistAddIcon />} onClick={handleOpen}>Add Workspace</Button>
                )}

            </div>
            {errorMessage ? (
                <div className='text-center text-xl font-semibold text-gray-600'>
                    {errorMessage}
                </div>
            ) : (
                <div>
                    <WorkSpaceTable loading={loading} workspace={workspace} getData={getData} />
                </div>
            )}

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <CreateWorkspace onClose={handleClose} getData={getData} currentPage={currentPage} />
                </Box>
            </Modal>
        </div>
    )
}

export default Workspaces;
