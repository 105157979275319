import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';
import { useSelectedWorkspace } from '../context/SelectedWorkspaceContext ';
import secureApiFetch from '../../services/secureFetchApi';

const GlobWorkspace = () => {
    const { setSelectedWorkspaceObject } = useSelectedWorkspace();

    const [workspacePermissionsData, setWorkspacePermissionsData] = useState([]);
    const [selectedWorkspace, setSelectedWorkspace] = useState('');

    const parseValue = JSON.parse(localStorage.getItem('workspaceDetails'))

    useEffect(() => {
        if (parseValue) {
            setSelectedWorkspace(parseValue.workspace_name)
            setSelectedWorkspaceObject(parseValue);
        } else {
            console.log('Workspace is not present in localstorage')
        }
    }, [selectedWorkspace])

    const fetchPermissionData = async () => {
        try {
            const permissionResponse = await secureApiFetch('/api/v1/workspace_permissions',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
            const data = await permissionResponse.json();

            setWorkspacePermissionsData(data)
            setSelectedWorkspaceObject(data[0]);

            const workspaces = data.map(permission => permission.workspace_name);
            if (workspaces.length > 0) {
                setSelectedWorkspace(workspaces[0]);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleWorkspaceChange = (event) => {
        const selectedWorkspaceName = event.target.value;
        setSelectedWorkspace(selectedWorkspaceName);
        const selectedWorkspaceObject = workspacePermissionsData.find(permission => permission.workspace_name === selectedWorkspaceName);
        setSelectedWorkspaceObject(selectedWorkspaceObject);
        localStorage.setItem('workspaceDetails', JSON.stringify(selectedWorkspaceObject))
    }

    useEffect(() => {
        fetchPermissionData()
    }, [])


    return (
        <div>
            <FormControl sx={{ m: 1, width: 200 }} size="small">
                <InputLabel id="demo-multiple-name-label">Workspaces</InputLabel>
                <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={selectedWorkspace}
                    onChange={handleWorkspaceChange}
                    size="small"
                    input={<OutlinedInput label="Workspaces" />
                }
                >
                    {workspacePermissionsData && workspacePermissionsData.map((permission) => (
                        <MenuItem key={permission.workspace_id} size="small" value={permission.workspace_name}>
                            {permission.workspace_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};


export default GlobWorkspace;