import React, { useState } from 'react';
import CustomBreadcrumbs from '../../ui/BreadCrumbs';
import Folder from '../../ui/Folder';
import Auth from '../../services/auth';
import AvatarApp from '../avatar/AvatarApp';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Lock from '@mui/icons-material/Lock';
import toast from 'react-hot-toast';
import { InputAdornment, IconButton } from '@mui/material';
import secureApiFetch from '../../services/secureFetchApi';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function Profile() {
    const user = Auth.getLoggedInUser();
    const [formData, setFormData] = useState({
        current_password: '',
        new_password: '',
    });
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.new_password !== confirmPassword) {
            toast.error("Passwords don't match");
            return;
        }

        secureApiFetch(`/api/v1/user/update/change_password`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((response) => {
                if (response.ok) {
                    toast.success('Password changed successfully');
                } else if (response.status === 404) {
                    throw new Error('Current password does not match');
                } else {
                    throw new Error('Failed to change password');
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };

    const toggleShowCurrentPassword = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };

    const toggleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div>
            <div className="flex flex-col">
                <CustomBreadcrumbs backUrl="/" backLabel="Home" />
                <Folder label={'Profile Information'} />
            </div>
            <hr className="my-2" />
            <div className="container mx-auto">
                <div className="flex flex-col md:flex-row items-center md:items-start md:space-x-8 my-4">
                    <AvatarApp email={user.email} size={'50px'} />
                    <div className="mt-4 md:mt-0">
                        <p>
                            <span className="font-bold">Username:</span> {user.sub}
                        </p>
                        <p>
                            <span className="font-bold">User Role:</span> {user.user_role}
                        </p>
                        <p>
                            <span className="font-bold">Id:</span> {user.id}
                        </p>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className="max-w-md mx-auto">
                    <div className="grid grid-cols-1 gap-6">
                        <TextField
                            fullWidth
                            label="Current Password"
                            name="current_password"
                            value={formData.current_password}
                            onChange={handleChange}
                            type={showCurrentPassword ? 'text' : 'password'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={toggleShowCurrentPassword}
                                            onMouseDown={(e) => e.preventDefault()} // Prevents focus shift
                                        >
                                            {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            fullWidth
                            label="New Password"
                            name="new_password"
                            value={formData.new_password}
                            onChange={handleChange}
                            type={showNewPassword ? 'text' : 'password'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={toggleShowNewPassword}
                                            onMouseDown={(e) => e.preventDefault()} // Prevents focus shift
                                        >
                                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            type={showConfirmPassword ? 'text' : 'password'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={toggleShowConfirmPassword}
                                            onMouseDown={(e) => e.preventDefault()} // Prevents focus shift
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div className="flex justify-end">
                            <Button variant="contained" color="customColor" type="submit">
                                Submit
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Profile;
