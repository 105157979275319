import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import Slide from '@mui/material/Slide';
import CustomBreadcrumbs from '../../../ui/BreadCrumbs'
import Folder from '../../../ui/Folder'
import secureApiFetch from '../../../services/secureFetchApi'
import { IconButton } from '@mui/material';
import TableLoader from '../../../ui/TableLoader';
import toast from 'react-hot-toast';
import ColorBadge from '../../../ui/ColorBadge';

const NotificationList = () => {
    const [notification, setNotification] = useState([])
    const [loading, setLoading] = useState(false)

    const getNotification = () => {
        secureApiFetch(`/api/v1/notification?status=read`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setNotification(data)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const onHandleRead = (id) => {
        secureApiFetch(`/api/v1/notification/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ notification_status: "read" })
        })
            .then(response => {
                getNotification()
                toast.success('notification status updated')
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const onHandleDelete = (id) => {
        secureApiFetch(`/api/v1/notification/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                getNotification()
                toast.success('Notification Deleted Successfully')
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        getNotification()
    }, [])

    return (
        <div>
            <CustomBreadcrumbs backUrl="/" backLabel="Task" />
            <div className='flex items-center justify-between'>
                <Folder label={'Task'} />
            </div>
            <hr className='mb-2' />
            <div className=''>
                <Paper sx={{ width: '100%' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead className='bg-[#3D3A89] text-white'>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Title</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Description</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Status</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Message Type</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading && (<TableRow>
                                    <TableCell colSpan={10}><TableLoader /></TableCell>
                                </TableRow>)}
                                {!loading && notification.length === 0 && (
                                    <tr>
                                        <td colSpan={10} align="center " className='py-3 font-bold text-xl text-red-600'>
                                            No Notification available
                                        </td>
                                    </tr>
                                )}
                                {!loading && notification.length > 0 && notification.length !== 0 && notification.map((item, index) => (
                                    <TableRow key={index} className={`${item.is_archived === true ? 'bg-[#f7f7f7]' : ''}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                        <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                            {item.title}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                            {item.description}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                            <ColorBadge badge={item.status} />
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                            {item.msg_type}
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton onClick={() => onHandleDelete(item.id)} aria-label="delete">
                                                <DeleteIcon className='text-red-700' />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        </div>
    )
}

export default NotificationList