import React, { useEffect, useState } from 'react'
import { LoadingSpinnerOpacity } from '../common/LoadingSpinner';
import { Cron } from 'react-js-cron'
import { useSelectedWorkspace } from '../context/SelectedWorkspaceContext ';
import { Box } from '@mui/material';

import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import ReactQuill from 'react-quill';

import secureApiFetch from '../../services/secureFetchApi';
import 'react-js-cron/dist/styles.css'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';


const ScheduleTaskForm = ({ workspacePermissionsData }) => {
    const { selectedWorkspaceObject } = useSelectedWorkspace()
    const [valueData, setValueData] = useState('0 0 1 * *')
    const [formDataScheduleTask, setFormDataScheduleTask] = useState({
        title: '',
        description: '',
        repetition: valueData,
        status: '',
        workspace_id: selectedWorkspaceObject.workspace_id,
        assignee_id: '',
        task_type: '',
        task_priority: 3,
        task_importance: ''
    });
    const [isWorkspaceSelected, setIsWorkspaceSelected] = useState(false);
    const [workspaceUsers, setWorkspaceUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        setFormDataScheduleTask(prevState => ({
            ...prevState,
            workspace_id: selectedWorkspaceObject.workspace_id
        }));
    }, [selectedWorkspaceObject.workspace_id]);

    useEffect(() => {
        const fetchWorkspaceUsers = async () => {
            try {
                if (formDataScheduleTask.workspace_id || selectedWorkspaceObject.workspace_id) {
                    setLoading(true)
                    const userResponse = await secureApiFetch(`/api/v1/workspace_permissions/${formDataScheduleTask.workspace_id || selectedWorkspaceObject.workspace_id}/users`,
                        {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            }
                        }
                    )
                    const data = await userResponse.json();
                    setWorkspaceUsers(data)
                }
            } catch (error) {
                console.error('Error fetching WorkspaceUsers:', error);
            } finally {
                setLoading(false)
            }
        };
        fetchWorkspaceUsers();
    }, [formDataScheduleTask.workspace_id, selectedWorkspaceObject.workspace_id]);

    useEffect(() => {
        setFormDataScheduleTask(prevState => ({
            ...prevState,
            'repetition': valueData
        }));
    }, [valueData])

    const handleScheduleTaskChange = (e) => {
        const { name, value } = e.target;

        if (name === 'workspace_id') {
            setIsWorkspaceSelected(!!value);
        }

        setFormDataScheduleTask(prevState => ({
            ...prevState,
            [name]: value,
        }));

    };

    const handleDescription = (value) => {
        setFormDataScheduleTask((prev) => ({
            ...prev,
            'description': value
        }))
    }

    useEffect(() => {
        if (selectedWorkspaceObject) {
            setIsWorkspaceSelected(!!selectedWorkspaceObject.workspace_id);
        }
    }, [selectedWorkspaceObject])

    const handleSubmitScheduleTask = async (e) => {
        e.preventDefault();
        try {
            setLoading(true)
            const scheduleTaskResponse = await secureApiFetch('/api/v1/schedule_task',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify(formDataScheduleTask)
                }
            )
            if (scheduleTaskResponse.status === 201) {
                toast.success('Schedule Task Created Successfully');
                navigate('/scheduletask')
                setFormDataScheduleTask({
                    title: '',
                    description: '',
                    repetition: '',
                    workspace_id: '',
                    assignee_id: '',
                    task_type: '',
                    task_priority: 'schedule',
                });
            } else if (scheduleTaskResponse.status === 403) {
                toast.error("Sorry, you don't have permission to schedule task.");
            } else {
                toast.error('An error occurred while scheduling the task');
            }
        } catch (error) {
            console.error('Error while scheduling task:', error);
        } finally {
            setLoading(false)
        }
    };

    const marks = [
        { value: 0, label: 'High 0' },
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5 Low' },
    ];
    const getSliderColor = (value) => {
        if (value === 0) return '#FF0000 ';
        if (value === 1) return '#FF6347';
        if (value === 2) return '#FFA07A ';
        if (value === 3) return '#94C973 ';
        if (value === 4) return '#006400 ';
        if (value === 5) return '#556B2F';
        return 'default';
    };

    return (
        <div className='relative '>
            {(!(workspacePermissionsData) || loading) && <LoadingSpinnerOpacity />}
            {workspacePermissionsData &&
                (
                    <form onSubmit={handleSubmitScheduleTask}>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div className="space-y-4">
                                <TextField
                                    name="title"
                                    label="Title"
                                    inputProps={{ maxLength: 60 }}
                                    value={formDataScheduleTask.title}
                                    onChange={handleScheduleTaskChange}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    name="task_type"
                                    label="Task Type"
                                    value={formDataScheduleTask.task_type}
                                    onChange={handleScheduleTaskChange}
                                    fullWidth
                                />
                                <ReactQuill
                                    theme="snow"
                                    name="description"
                                    value={formDataScheduleTask.description}
                                    onChange={handleDescription}
                                    className='h-48'
                                />
                            </div>
                            <div className="space-y-4">
                                <div className="grid grid-cols-2 gap-4">
                                    <FormControl fullWidth>
                                        <InputLabel>Assign To</InputLabel>
                                        <Select
                                            name="assignee_id"
                                            value={formDataScheduleTask.assignee_id}
                                            onChange={handleScheduleTaskChange}
                                            input={<OutlinedInput label="Assign To" />}
                                            required
                                            disabled={!isWorkspaceSelected}
                                        >
                                            {workspaceUsers.map(userData => (
                                                <MenuItem key={userData.id} value={userData.user_id}>
                                                    {userData.username}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel>Workspace</InputLabel>
                                        <Select
                                            name="workspace_id"
                                            value={formDataScheduleTask.workspace_id || selectedWorkspaceObject.workspace_id}
                                            onChange={handleScheduleTaskChange}
                                            input={<OutlinedInput label="Workspace" />}
                                            required
                                        >
                                            {workspacePermissionsData.map(workspace => (
                                                <MenuItem key={workspace.id} value={workspace.workspace_id}>
                                                    {workspace.workspace_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            value={formDataScheduleTask.status}
                                            onChange={handleScheduleTaskChange}
                                            label="Status"
                                            name='status'
                                        >
                                            <MenuItem value="on_hold">On Hold</MenuItem>
                                            <MenuItem value="dependent_hold">Dependent Hold</MenuItem>
                                            <MenuItem value="todo">To Do</MenuItem>
                                            <MenuItem value="inprogress">In Progress</MenuItem>
                                            <MenuItem value="completed">Completed</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel>Task Importance</InputLabel>
                                        <Select
                                            name="task_importance"
                                            value={formDataScheduleTask.task_importance}
                                            onChange={handleScheduleTaskChange}
                                            input={<OutlinedInput label="Task Importance" />}
                                        >
                                            <MenuItem value="do_first">Do First</MenuItem>
                                            <MenuItem value="schedule">Schedule</MenuItem>
                                            <MenuItem value="delegate">Delegate</MenuItem>
                                            <MenuItem value="eliminate">Eliminate</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Box sx={{ width: 200, marginLeft: 2 }}>
                                        Task Priority
                                        <Slider
                                            name="task_priority"
                                            aria-label="Task Priority"
                                            value={formDataScheduleTask.task_priority}
                                            step={1}
                                            onChange={handleScheduleTaskChange}
                                            min={0}
                                            max={5}
                                            marks={marks}
                                            valueLabelDisplay="off"
                                            color="customColor"
                                            label={true}
                                            sx={{
                                                '& .MuiSlider-thumb': {
                                                    color: getSliderColor(formDataScheduleTask.task_priority),
                                                },
                                                '& .MuiSlider-track': {
                                                    color: getSliderColor(formDataScheduleTask.task_priority),
                                                },
                                                '& .MuiSlider-rail': {
                                                    color: getSliderColor(formDataScheduleTask.task_priority),
                                                },
                                            }}
                                        />
                                    </Box>
                                </div>
                                <div>
                                    Please Set repetition
                                    <Cron name="repetition" value={valueData} setValue={setValueData} />
                                </div>
                                <TextField
                                    name="repetition"
                                    label="Repetition"
                                    value={valueData}
                                    onChange={handleScheduleTaskChange}
                                    fullWidth
                                    required
                                />

                            </div>
                        </div>
                        <div className="fixed bottom-6 right-10">
                            <Button type="submit" variant="contained" color="customColor" size="small">
                                Schedule Task
                            </Button>
                        </div>
                    </form>
                )
            }
        </div>
    )
}

export default ScheduleTaskForm
