import React, { useState } from 'react';
import { TextField, Button, Container, Grid, Typography, IconButton } from '@mui/material';
import secureApiFetch from '../../services/secureFetchApi';
import CloseIcon from '@mui/icons-material/Close';
import { toast, Toaster } from 'react-hot-toast';

export const CreateWorkspace = ({ onClose, getData, currentPage }) => {
    const [formData, setFormData] = useState({
        workspace_name: '',
        description: ''
    });


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        secureApiFetch('/api/v1/workspace', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then((resp) => {
                setFormData({ workspace_name: '', description: '' });
                getData(currentPage)
                onClose()
                if (resp.ok) {
                    toast.success("Workspace Created successfully");
                } else {
                    throw new Error("Failed to Create Workspace");
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
    };



    const isButtonDisabled = formData.workspace_name.length < 1 || formData.description.length < 1;

    return (
        <div >
            <Toaster position='top-center' reverseOrder={false} />
            <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: '1rem' }}>
                <Grid item>
                    <Typography variant="h6" gutterBottom>Create Workspace Form</Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <div className='mt-2'>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Workspace Name"
                                inputProps={{ maxLength: 30 }}
                                name="workspace_name"
                                value={formData.workspace_name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                multiline
                                maxRows={4}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="customColor" disabled={isButtonDisabled}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>

        </div>
    );
};
