
const Auth = {
    removeSession: () => {
        localStorage.clear();
    },

    getLoggedInUser: () => {
        const token = localStorage.getItem('token')

        if (token) {
            const parts = token.split('.');

            const decodedPayload = atob(parts[1]);

            const payload = JSON.parse(decodedPayload);

            const user = {
                access_token: token, ...payload
            }
            return user;
        }
        return
    }
}

export default Auth;