import React from 'react';
import { createAvatar } from '@dicebear/avatars';
import { bottts } from '@dicebear/collection';

const AvatarApp = ({ email, size }) => {
    const seed = email.toLowerCase().trim();

    const svg = createAvatar(bottts, {
        seed,
    });

    return (
        <div dangerouslySetInnerHTML={{ __html: svg }} style={{ width: size, height: size }} />
    );
};

export default AvatarApp;
