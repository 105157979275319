import React, { useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import secureApiFetch from '../../../services/secureFetchApi';
import ReplayIcon from '@mui/icons-material/Replay';
import DateFormat from '../../../ui/DateFormat';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import SendIcon from '@mui/icons-material/Send';
import DialogTitle from '@mui/material/DialogTitle';
import './comment.css'
import Auth from '../../../services/auth';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, { timelineOppositeContentClasses, } from '@mui/lab/TimelineOppositeContent';
import { Tooltip } from '@mui/material';

const Comments = ({ task_id }) => {
    const user = Auth.getLoggedInUser();
    const [comment, setComment] = useState([])
    const [commentSysytem, setCommentSystem] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [commentId, setCommentId] = useState(0)
    const [openClick, setOpenClick] = useState(false);
    const [filterTitle, setFilterTitle] = useState([])
    const [selectedOption, setSelectedOption] = useState('All');
    const [formData, setFormData] = useState({
        'title': 'comment',
        'description': '',
        'task_id': ''
    })
    const User_Id = user.id
    const open = Boolean(anchorEl);

    const handleClickOpen = (id) => {
        setOpenClick(true);
        setCommentId(id)
        handleClose()
    };
    const handleCloseClick = () => { setOpenClick(false) };
    const handleClick = (event) => { setAnchorEl(event.currentTarget) };
    const handleClose = () => { setAnchorEl(null) };

    const getData = () => {
        let url = `/api/v1/comment/${task_id}`
        secureApiFetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                setComment(data)
                const names = data.map(task => ({
                    title: task.title,
                }));
                const uniqueNamesSet = new Set(names.map(JSON.stringify));
                const uniqueNames = Array.from(uniqueNamesSet).map(JSON.parse);
                setFilterTitle(uniqueNames)
            })
            .catch((error) => {
                console.error('Fetch error:', 'Failed to fetch data')
            })
    }
    const getDataBySystem = () => {
        let url = `/api/v1/comment/${task_id}?title=system`
        secureApiFetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                setCommentSystem(data)
            })
            .catch((error) => {
                console.error('Fetch error:', 'Failed to fetch data')
            })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prev) => ({
            ...prev,
            [name]: value,
            'task_id': task_id
        }))
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        secureApiFetch(`/api/v1/comment`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(formData)
            }
        )
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                getData()
                getDataBySystem()
                toast.success("Comment Added");
                return response.json();
            }).then((data) => {
                document.getElementById('outlined-basic').value = '';
            })
            .catch(error => {
                toast.error(error.message);
            });
    }

    const handleDelete = (event, id) => {
        event.preventDefault()
        secureApiFetch(`/api/v1/comment/${id}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            }
        )
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                getData()
                handleClose()
                toast.success("Comment Deleted Successfully");
                return response.json();
            })
            .catch(error => {
                toast.error(error.message);
            });

    }

    const handleEditChange = (event) => {
        const { name, value } = event.target
        setFormData((prev) => ({
            ...prev,
            [name]: value,
            'task_id': task_id
        }))
    }
    const handleEditSubmit = (event) => {
        event.preventDefault()
        const formValue = {
            'title': formData.title,
            'description': formData.description,
        }
        secureApiFetch(`/api/v1/comment/${commentId}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(formValue)
            }
        )
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                getData()
                handleCloseClick()
                toast.success("Comment Updated");
                return response.json();
            })
            .catch(error => {
                toast.error(error.message);
            });
    }

    const handleFilter = (event) => {
        const titleName = event.target.value
        setSelectedOption(titleName);
        let url = `/api/v1/comment/${task_id}`
        if (titleName !== 'All') {
            url = `/api/v1/comment/${task_id}?title=${titleName}`
        }
        secureApiFetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                setComment(data)
            })
            .catch((error) => {
                console.error('Fetch error:', 'Failed to fetch data')
            })
    }

    const handleReload = () => {
        if (task_id) {
            getData()
            getDataBySystem()
        }
    }

    useEffect(() => {
        if (task_id) {
            getData()
            getDataBySystem()
        }
    }, [task_id])

    return (
        <div className='flex flex-col border w-full'>
            <div className='flex w-full'>
                <label className='w-[37%] p-2'>Task Timeline</label>
                <label className='w-[62%] p-2 border-l'>Discussion</label>
            </div>
            <hr />
            <div className='flex'>
                <div className='w-[37%] h-full'>
                    <Timeline sx={{ [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.2, }, maxHeight: '40rem', overflowY: 'scroll' }} className='custom-scrollbar-timeline'>
                        {commentSysytem.length > 0 && commentSysytem.map((cmt) => (
                            <TimelineItem>
                                <TimelineOppositeContent sx={{ fontSize: '10px', fontWeight: '600' }} >
                                    <Tooltip title={cmt.insert_ts}>
                                        {cmt.insert_ts.substring(5, 10)}
                                    </Tooltip>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot variant="outlined" color="customColor" />
                                    <TimelineConnector sx={{ bgcolor: '#3A3D89' }} />
                                </TimelineSeparator>
                                <TimelineContent sx={{ fontSize: '10px', py: '12px', px: '12px' }}>
                                    {cmt.description}
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                    </Timeline>
                </div>

                <div className='w-[62%] h-full border-l'>
                    <div className="container min-w-[40rem] mx-3">
                        <div className='flex items-center justify-between mt-2 px-2'>
                            <div>
                                <FormControl variant="outlined" sx={{ minWidth: 160 }} size="small">
                                    <InputLabel >Title</InputLabel>
                                    <Select
                                        label='Title'
                                        name="user"
                                        value={selectedOption}
                                        color='customColor'
                                        onChange={handleFilter}
                                    >
                                        <MenuItem value="All">All Comments</MenuItem>
                                        {filterTitle && filterTitle.map((user) => (
                                            <MenuItem key={user.title} value={user.title}>{user.title}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <Button onClick={handleReload} variant='contained' color='customColor' endIcon={<ReplayIcon />} >Reload Chat</Button>
                            </div>
                        </div>
                        <div className="flex flex-col justify-start mt-5 w-full overflow-auto container px-2 my-2 h-[30rem] custom-scrollbar">
                            {comment.length > 0 && comment.map((cmt) => (
                                <div>
                                    {User_Id === cmt.user_id ?
                                        <div className="flex justify-end mb-4">
                                            <div>
                                                <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button', }} anchorEl={anchorEl} open={open} onClose={handleClose} PaperProps={{ style: { width: '20ch', }, }}>
                                                    <MenuItem onClick={(event) => handleDelete(event, cmt.comment_id)}>
                                                        Delete
                                                    </MenuItem>
                                                    <MenuItem onClick={() => handleClickOpen(cmt.comment_id)}>
                                                        Edit
                                                    </MenuItem>
                                                </Menu>
                                            </div>
                                            <div className="mr-1 py-1 px-2  bg-blue-100 rounded-bl rounded-tr rounded-tl text-white">
                                                <div className='flex justify-between items-center mb-1 space-x-4'>
                                                    <label className='text-[#3A3D89] text-xs font-medium'>{cmt.username}</label>
                                                    <span className='text-gray-600 text-xs'>
                                                        <DateFormat date={cmt.insert_ts} />
                                                    </span>
                                                </div>
                                                <p className='text-xs text-black font-medium max-w-[30rem] overflow-hidden'>
                                                    {cmt.title}: &nbsp;{JSON.stringify(cmt.description) || 'NA'}
                                                </p>
                                            </div>

                                            {/* <Avatar name={cmt.username} /> */}
                                        </div>
                                        :
                                        <div className="flex justify-start mb-4">
                                            {/* <Avatar name={cmt.username} /> */}
                                            <div className="ml-1 py-1 px-2 bg-[#edeeff] rounded-br rounded-tr rounded-tl text-white">

                                                <div className='flex justify-between items-center mb-1 space-x-4'>
                                                    <label className='text-[#3A3D89] text-xs font-medium'>{cmt.username}</label>
                                                    <span className='text-gray-600 text-xs'>
                                                        <DateFormat date={cmt.insert_ts} />
                                                    </span>
                                                </div>

                                                <p className='text-xs text-black font-medium max-w-[30rem] overflow-hidden'>
                                                    {cmt.title}: &nbsp;{cmt.description || 'NA'}
                                                </p>

                                            </div>
                                        </div>
                                    }

                                </div>
                            ))}
                        </div>

                        <form onSubmit={handleSubmit} className='flex space-x-2 items-center px-2 py-4 '>

                            <Box sx={{ width: 300 }}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="demo-simple-select-label">Message Type</InputLabel>
                                    <Select
                                        name='title'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formData.title}
                                        label="Message Type"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value='comment'>Comment</MenuItem>
                                        <MenuItem value='update'>Update</MenuItem>
                                        <MenuItem value='note'>Note</MenuItem>
                                        <MenuItem value='other'>Other</MenuItem>
                                        <MenuItem value='follow_up'>Follow up</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <TextField id="outlined-basic" label="Message" size='small' variant="outlined" sx={{ width: '75%' }}
                                name='description'
                                onChange={handleChange}
                                required
                            />
                            <button type='submit' className='bg-[#3A3D89] px-2 py-2 rounded text-white space-x-1 flex items-center text-sm'>
                                Send <SendIcon className='mx-1' fontSize='small' />
                            </button>
                        </form>
                    </div>
                </div>
            </div>


            <Dialog
                fullWidth={true}
                open={openClick}
                maxWidth='md'
                onClose={handleCloseClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Edit Message"}
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleEditSubmit} className='flex items-center space-x-2 py-2'>
                        <Box>
                            <FormControl sx={{ width: 200 }}>
                                <InputLabel id="demo-simple-select-label">Message Type</InputLabel>
                                <Select
                                    name='title'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formData.title}
                                    label="Message Type"
                                    onChange={handleEditChange}
                                >
                                    <MenuItem value='Comment'>Comment</MenuItem>
                                    <MenuItem value='Update'>Update</MenuItem>
                                    <MenuItem value='Note'>Note</MenuItem>
                                    <MenuItem value='Other'>Other</MenuItem>
                                    <MenuItem value='Follow up'>Follow up</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <TextField id="outlined-basic" label="Message" variant="outlined" sx={{ width: '75%' }}
                            name='description'
                            onChange={handleEditChange}
                            required
                        />
                        <Button type='submit' variant='contained' color='customColor'>Submit</Button>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default Comments;