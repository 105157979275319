import React from 'react'
import Chart from 'react-apexcharts';

const TaskChartTable = ({ tasks }) => {
    const chartOptions = {
        chart: {
            type: 'bar',
            height: 100,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                distributed: true,
                horizontal: false,
                columnWidth: '30%',
                endingShape: 'rounded'
            }
        },
        dataLabels: {
            enabled: true
        },
        xaxis: {
            categories: [
                'Planned',
                'To Do',
                'In Progress',
                'Completed',
                'On Hold',
                'Dependent Hold',
            ],
            labels: {
                show: false
            }
        },
        colors: ['#d49069', '#FFC000', '#00B050', '#0070C0', '#FF0000', '#fecaca'],
        legend: {
            show: true
        },
        title: {
            text: `Total Task Count: ${tasks.total_count}`,
            align: 'center',
            margin: 10,
            offsetY: 20,
            style: {
                fontSize: '16px'
            }
        },
    };

    const chartSeries = [{
        name: 'Count',
        data: [
            tasks.planned || 0,
            tasks.todo || 0,
            tasks.inprogress || 0,
            tasks.completed || 0,
            tasks.on_hold || 0,
            tasks.dependent_hold || 0,
        ]
    }];
    console.log('tasks',tasks)

    return (
        <div style={{ maxWidth: '550px', margin: 'auto' }}>
            <Chart options={chartOptions} series={chartSeries} type="bar" height={250} />
        </div>
    );
};

export default TaskChartTable