import { Route } from "react-router-dom";
import TaskList from "./list";
import CreateTaskList from "./create";
import TaskDetails from "./details";

const TaskListRoutes = [
    <Route path={`/tasks?`} element={<TaskList />} />,
    <Route path={`/tasks/:id`} element={<TaskList />} />,
    <Route path={`/tasks/create`} element={<CreateTaskList />} />,
    <Route path={`/tasks/detail/:taskid`} element={<TaskDetails />} />
]
export default TaskListRoutes;