import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, Toaster } from 'react-hot-toast';
import { Button, IconButton } from "@mui/material";

import BorderColorIcon from '@mui/icons-material/BorderColor';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import EditIcon from '@mui/icons-material/Edit';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import secureApiFetch from "../../services/secureFetchApi";
import CustomBreadcrumbs from '../../ui/BreadCrumbs';
import DateManager from "../../features/DateManager";
import ColorBadge from "../../ui/ColorBadge";
import DetailsTab from "./DetailsTab";
import Folder from '../../ui/Folder';
import TaskEdit from "./edit";
import AttachTab from "./AttachTab";
import PriorityBadge from "../../ui/PriorityBadge";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const TaskDetails = () => {
    const [value, setValue] = React.useState(0);
    const [taskDetail, setTaskDetail] = useState({})
    const [open, setOpen] = useState(false);
    const [editTitle, setEditTitle] = useState(false);
    const [editId, setEditId] = useState(0)
    const { taskid } = useParams()
    const [task, setTask] = useState([])
    const [changeTitle, setChangeTitle] = useState('')

    const handleClose = () => { setOpen(false) };

    const handleClickOpen = (id) => {
        setOpen(true);
        setEditId(id)
    };

    const handleChangeEvent = (event, newValue) => {
        setValue(newValue);
    };

    const getData = () => {
        secureApiFetch(`/api/v1/task/${taskid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                setTaskDetail(data)
            })
            .catch((error) => {
                console.log('Fetch error:', 'Failed to fetch data')
                toast.error('An error occurred while fetching data')
            })
    }

    const getDataStatus = () => {
        secureApiFetch(`/api/v1/task/${taskDetail.task_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setTask(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleChange = (event) => {
        const statusValue = event.target.value
        const addTask = ({
            "status": statusValue,
        })
        secureApiFetch(`/api/v1/task/${taskDetail.task_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(addTask)
        })
            .then(response => {
                if (response.ok) {
                    getData()
                    toast.success("Task status Updated successfully");
                } else {
                    throw new Error("Failed to update task status");
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
    };

    const handleSubmitTitle = (event, nameValue, value, mainValue, setValue) => {
        event.preventDefault()
        const changeTitleName = ({
            [nameValue]: value,
        })
        console.log(nameValue, '', value)
        secureApiFetch(`/api/v1/task/${taskDetail.task_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(changeTitleName)
        })
            .then(response => {
                if (response.ok) {
                    getData()
                    toast.success("Task User Updated successfully");
                } else {
                    throw new Error("Failed to update task User");
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
        setValue(!mainValue)
    }

    useEffect(() => {
        if (taskid) {
            getData()
            setChangeTitle(taskDetail.title)
        }
    }, [])
    useEffect(() => {
        setChangeTitle(taskDetail.title)
    }, [taskDetail.title])

    useEffect(() => {
        if (taskDetail.task_id) {
            getDataStatus()
        }
    }, [taskDetail])

    return (
        <div>
            <Toaster position="top-center" reverseOrder={false} />
            <div className='flex flex-col '>
                <CustomBreadcrumbs backUrl="/" backLabel="TaskList" currentLabel='Task' />
                <div className="flex items-center">
                    <Folder label={taskDetail.title} />
                    <IconButton onClick={() => setEditTitle(!editTitle)}>
                        <EditIcon sx={{ fontSize: 13 }} className="text-[#3A3D89]" />
                    </IconButton>
                    {editTitle && (
                        <div>
                            <input type="text" onChange={(e) => setChangeTitle(e.target.value)} value={changeTitle} maxLength={60} className='px-2 py-0.5 mx-2 border rounded text-sm font-medium focus:outline-none' />
                            <button onClick={(e) => handleSubmitTitle(e, 'title', changeTitle, editTitle, setEditTitle)} className='border-black w-8 text-[#3A3D89] font-medium py-0'>Save</button>
                        </div>
                    )}
                </div>
            </div>
            <div className="mb-2 px-4 flex items-center justify-between">
                <div className="flex space-x-2 items-center">

                    <Tooltip title="Status">
                        <span><ColorBadge badge={taskDetail.status} /></span>
                    </Tooltip>
                    <Tooltip title="Priority">
                        <span><PriorityBadge badge={taskDetail.task_priority} /></span>
                    </Tooltip>
                    <Tooltip title="Importance">
                        <span><PriorityBadge badge={taskDetail.task_importance} /></span>
                    </Tooltip>
                    <Tooltip title="Type">
                        <span><ColorBadge badge={taskDetail.task_type} /></span>
                    </Tooltip>

                    {taskDetail.is_archived === true &&
                        <ColorBadge badge='Archive' />
                    }
                    <DateManager endDate={taskDetail.end_date} />
                </div>

                <div className="flex space-x-2 items-center ">
                    <FormControl sx={{  minWidth: 160 }} size="small">
                        <InputLabel id="demo-select-small-label" color="customColor">Update Status</InputLabel>
                        <Select labelId="demo-select-small-label" id="demo-select-small" label="Update Status" name="status" value={task.status} onChange={handleChange} color="customColor">
                            <MenuItem value="planned">Planned</MenuItem>
                            <MenuItem value="on_hold">On Hold</MenuItem>
                            <MenuItem value="dependent_hold">Dependent Hold</MenuItem>
                            <MenuItem value="todo">To Do</MenuItem>
                            <MenuItem value="inprogress">In Progress</MenuItem>
                            <MenuItem value="completed">Completed</MenuItem>
                        </Select>
                    </FormControl>
                    <Button onClick={() => handleClickOpen(taskDetail.task_id)} variant="contained" color="customColor" endIcon={<BorderColorIcon />}>
                        Edit
                    </Button>
                </div>
            </div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChangeEvent} aria-label="basic tabs example" >
                        <Tab label="Details" {...a11yProps(0)} />
                        <Tab label="Attachment" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <DetailsTab getData={getData} taskDetail={taskDetail} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <AttachTab taskId={taskDetail.task_id} />
                </CustomTabPanel>
            </Box>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                maxWidth={'xl'}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <TaskEdit editId={editId} onClose={handleClose} taskData={getData} />
                </DialogContent>
            </Dialog>

        </div>
    )
}
export default TaskDetails;