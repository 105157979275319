import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useSelectedWorkspace } from '../../context/SelectedWorkspaceContext ';
import { TextField, FormControl, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, CircularProgress } from '@mui/material';

import secureApiFetch from '../../../services/secureFetchApi'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

const TaskCalender = () => {
    const localizer = momentLocalizer(moment);
    const { selectedWorkspaceObject } = useSelectedWorkspace();
    const [taskData, setTaskData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }
    const formatDate = (date) => date.toISOString().split('T')[0];

    const getTask = (from_date, to_date) => {
        const url = `/api/v1/task/filter_by/start_dates/workspace/${selectedWorkspaceObject.workspace_id}?from_date=${from_date}&to_date=${to_date}`;
        secureApiFetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        })
            .then((resp) => resp.json())
            .then((data) => {
                const formattedEvents = data.map(event => ({
                    id: event.task_id,
                    title: `${event.title} | ${event.assignee_name}`,
                    start: new Date(event.start_date),
                    end: new Date(event.end_date),
                    priority: event.task_priority,
                }));
                setTaskData(formattedEvents)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log('Error Fetching Data', error);
            });
    };

    useEffect(() => {
        if (selectedWorkspaceObject) {
            const todayDate = new Date();

            const futureDate = new Date(todayDate);
            futureDate.setDate(todayDate.getDate() + 182);

            const pastDate = new Date(todayDate);
            pastDate.setDate(todayDate.getDate() - 182);

            getTask(formatDate(pastDate), formatDate(futureDate));
        }
    }, [selectedWorkspaceObject]);

    const handleSubmit = () => {
        if (startDate && endDate) {
            getTask(startDate, endDate)
        }
        handleClose()
    }

    const eventPropGetter = (event) => {
        let backgroundColor = '#3174ad';
        if (event.priority === 0) backgroundColor = '#FF0000';
        if (event.priority === 1) backgroundColor = '#FF6347';
        if (event.priority === 2) backgroundColor = '#FFA07A';
        if (event.priority === 3) backgroundColor = '#94C973';
        if (event.priority === 4) backgroundColor = '#006400';
        if (event.priority === 5) backgroundColor = '#556B2F';
        return {
            style: {
                backgroundColor,
                fontSize: '10px'
            }
        };
    };
    const CustomAgendaEvent = ({ event }) => {
        return (
            <span>
                <Link to={`/tasks/detail/${event.id}`}>{event.title}</Link>
            </span>
        );
    };

    if (loading) {
        return <div>Loading...</div>;
    }
    if (taskData.length === 0) {
        return <div>No events available for the selected period.</div>;
    }
  
    return (
        <div >
            <div className='flex justify-end mb-1'>
                <button onClick={handleClickOpen} className='bg-[#3A3D89] p-1 rounded text-white  text-xs'>
                    Select Custom Date Range
                </button>
            </div>
            <Calendar
                localizer={localizer}
                events={taskData}
                startAccessor="start"
                endAccessor="end"
                views={['month', 'week', 'agenda']}
                defaultView="month"
                style={{ width: '100%', height: '30rem' }}
                selectable={false}
                popup={true}
                eventPropGetter={eventPropGetter}
                components={{
                    agenda: {
                        event: CustomAgendaEvent
                    }
                }}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Select Custom Date Range"}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                        <TextField
                            size='small'
                            label="Start Date"
                            variant="outlined"
                            margin="normal"
                            value={startDate}
                            onChange={(event) => setStartDate(event.target.value)}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                        <TextField
                            size='small'
                            label="End Date"
                            variant="outlined"
                            margin="normal"
                            value={endDate}
                            onChange={(event) => setEndDate(event.target.value)}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} variant='contained' color='customColor' autoFocus>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default TaskCalender