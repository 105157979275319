import React, { useEffect, useState } from 'react';

const RelativeTime = ({ dateTime }) => {
    const [timeAgo, setTimeAgo] = useState('');

    useEffect(() => {
        const getTimeDifference = () => {
            const now = new Date();
            const date = new Date(dateTime);
            const difference = now.getTime() - date.getTime();

            const seconds = Math.floor(difference / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
            if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
            if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
            return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        };

        setTimeAgo(getTimeDifference());

        const intervalId = setInterval(() => {
            setTimeAgo(getTimeDifference());
        }, 60000); 

        return () => clearInterval(intervalId);
    }, [dateTime]);

    return (
        <div>
            {timeAgo}
        </div>
    );
};

export default RelativeTime;
