import React from 'react'
import FolderCopyRoundedIcon from '@mui/icons-material/FolderCopyRounded';


const Folder = ({ label }) => {
  return (
    <div className='m-1 flex items-center space-x-2'>
      <span className='bg-[#DFDFEE] p-2  rounded-full'>
        <FolderCopyRoundedIcon fontSize="small" color="customColor" />
      </span>
      <span className='text-sm font-semibold '>{label}</span>
    </div>
  )
}

export default Folder