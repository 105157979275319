import React, { useEffect, useState } from 'react'
import Navbar from '../appbar/Navbar'
import Sidebar from '../appbar/Sidebar'
import Footer from '../appbar/Footer';
import { Outlet } from 'react-router-dom';
import secureApiFetch from '../../services/secureFetchApi';
import LoadingSpinner from '../common/LoadingSpinner';
import { SelectedWorkspaceProvider } from '../context/SelectedWorkspaceContext ';
import toast, { Toaster } from 'react-hot-toast';

function Index() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [showDataUser, setShowDataUser] = useState(false);
  const [online, setOnline] = useState(navigator.onLine);

  const getUserWorkspace = () => {
    secureApiFetch(`/api/v1/workspace_permissions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.length > 0) {
          setShowDataUser(!showDataUser)
        }
      })
  }

  const isUserSignedIn = async () => {
    setLoading(true)
    try {
      const response = await secureApiFetch(`/api/v1/check_sign_in`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
      )
      response ? setShowData(true) : setShowData(false)
    } catch (error) {
      console.error('Error while checking is user signed in:', error);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    isUserSignedIn()
    getUserWorkspace()
  }, [])

  useEffect(() => {
    const handleOnline = () => {
      setOnline(true);
      toast.success("You are now online!");
    };
    const handleOffline = () => {
      setOnline(false);
      toast.error("You are now offline. Please check your internet connection.");
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <SelectedWorkspaceProvider>
        {loading && <LoadingSpinner />}
        {showData && (
          <>
            {showDataUser ? (
              <div className='bg-gray-100'>
                <Navbar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
                <div className='flex mx-3'>
                  <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
                  <div className='my-2 mx-1 p-1 w-full bg-white rounded-md shadow-lg min-h-screen'>
                    <Outlet />
                  </div>
                </div>
                <Footer />
              </div>
            ) : (
              <div className="text-center mt-20">
                <p className="text-gray-500">You are not added in any workspace. Please contact your administrator to add you to a workspace.</p>
              </div>
            )}
          </>
        )}
      </SelectedWorkspaceProvider>
    </div>
  )
}

export default Index  