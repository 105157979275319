import React from 'react';

const PageNotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-200">
      <div className="text-4xl text-red-600 font-bold">404 - Page Not Found</div>
    </div>
  );
};

export default PageNotFound;
