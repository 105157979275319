import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const TableLoader = () => {
    return (
        <Box>
            <Skeleton animation="wave" sx={{ height: 30, marginBottom: 1 }} />
            <Skeleton animation="wave" sx={{ height: 30, marginBottom: 1 }} />
            <Skeleton animation="wave" sx={{ height: 30, marginBottom: 1 }} />
        </Box>
    )
}

export default TableLoader