import React from 'react';
import { Grid, Paper, Box } from '@mui/material';
import CustomBreadcrumbs from '../../ui/BreadCrumbs'
import Folder from '../../ui/Folder'
import DashLink from './DashLink';
import UserTaskCount from './dashboard list/UserTaskCount';

const Dashboard = () => {
    const calculateGridItemWidth = (space) => {
        switch (space) {
            case 1:
                return 4;
            case 2:
                return 6;
            case 3:
                return 8;
            case 4:
                return 12;
            default:
                return 4;
        }
    };

    return (
        <div>
            <div className='flex justify-between items-center'>
                <div className='flex flex-col'>
                    <CustomBreadcrumbs backUrl="/" backLabel="Home" />
                    <Folder label={'Dashboard'} />
                </div>
                <div>
                    <UserTaskCount />
                </div>
            </div>

            <hr />
            <div className='my-4'>
                <div className='grow px-2'>
                    <Grid container spacing={1}>
                        {Object.entries(DashLink).map(([key, value]) => (
                            <Grid item xs={20} sm={calculateGridItemWidth(value.space)} key={key}>
                                {value.visible && (
                                    <Paper sx={{ padding: 1, textAlign: 'left' }}>
                                        <Box sx={{ marginBottom: 1 }}>
                                            <h3 className='font-medium text-sm'>{value.title}</h3>
                                        </Box>
                                        {value.component}
                                    </Paper>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        </div>

    );
};

export default Dashboard;
