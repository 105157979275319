import React, { useEffect, useState, useCallback } from 'react';
import { IconButton } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import secureApiFetch from '../services/secureFetchApi';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const TaskFilter = ({ totalPages, page, setTotalPages, workId, setTaskData, setCurrentPage, setLoading, assigneeNames }) => {
    const [filters, setFilters] = useState({ status: '', user: '', sort: '', descending: '', archived: false });

    const fetchData = useCallback(async (url) => {
        setLoading(true);
        const config = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        };
        const res = await secureApiFetch(url, config);
        if (res.headers.has('X-Page-Count')) {
            const totalPagesCount = parseInt(res.headers.get('X-Page-Count'));
            setTotalPages(totalPagesCount);
        }
        const data = await res.json();
        setTaskData(data);
        setLoading(false);
    }, [setLoading, setTaskData, setTotalPages]);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        fetchTasks(newPage);
    };

    const fetchTasks = (page) => {
        const { status, user, sort, descending, archived } = filters;
        let url = `/api/v1/task/workspace/${workId}?page=${page}`;
        if (status) url += `&task_status=${status}`;
        if (user) url += `&assignee_id=${user}`;
        if (sort) url += `&sort_by=${sort}`;
        if (descending) url += `&descending=${descending}`;
        if (archived) url += `&skip_archived=false`;
        fetchData(url);
    };

    useEffect(() => {
        if (workId) {
            fetchTasks(1);
        }
    }, [filters.status, filters.user, filters.sort, filters.descending, filters.archived, fetchData, workId]);

    const handleFiltersChange = (event) => {
        const { name, value } = event.target;
        setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
    };

    const handleSortToggle = () => {
        const newDescending = filters.descending === 'true' ? 'false' : 'true';
        setFilters(prevFilters => ({ ...prevFilters, descending: newDescending }));
    };

    const handleChangeArchive = (event) => {
        const newArchived = event.target.checked
        setFilters(prevFilters => ({ ...prevFilters, archived: newArchived }));
    };

    return (
        <div>
            <div className="flex justify-center">
                <Stack spacing={2}>
                    <Pagination count={totalPages} page={page} onChange={handleChangePage} size="large" />
                </Stack>
            </div>
            <div className="flex justify-between items-center">
                <div className='text-xs me-2 pl-2 font-medium rounded bg-gray-100'>
                    <label className='px-2'>Archive</label>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filters.archived}
                                onChange={handleChangeArchive}
                                name="archived"
                                color="customColor"
                                sx={{ px: 0, pl: 1 }}
                            />
                        }
                    />
                </div>
                <div>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel>Status</InputLabel>
                        <Select
                            name="status"
                            label="Status"
                            value={filters.status}
                            onChange={handleFiltersChange}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="planned">Planned</MenuItem>
                            <MenuItem value="on_hold">On Hold</MenuItem>
                            <MenuItem value="dependent_hold">Dependent Hold</MenuItem>
                            <MenuItem value="todo">Todo</MenuItem>
                            <MenuItem value="inprogress">In Progress</MenuItem>
                            <MenuItem value="completed">Completed</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
                        <InputLabel>Assignee Name</InputLabel>
                        <Select
                            label="Assignee Name"
                            name="user"
                            value={filters.user}
                            onChange={handleFiltersChange}
                        >
                            <MenuItem value="">All</MenuItem>
                            {assigneeNames && assigneeNames.map((user) => (
                                <MenuItem key={user.assignee_id} value={user.assignee_id}>{user.assignee_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel>Sort By</InputLabel>
                        <Select
                            label="Sort By"
                            name="sort"
                            value={filters.sort}
                            onChange={handleFiltersChange}
                        >
                            <MenuItem value="">None</MenuItem>
                            <MenuItem value="status">Status</MenuItem>
                            <MenuItem value="start_date">Start Date</MenuItem>
                            <MenuItem value="end_date">End Date</MenuItem>
                        </Select>
                    </FormControl>

                    <IconButton onClick={handleSortToggle}>
                        {filters.descending === 'true'
                            ? <ArrowDownwardIcon color="customColor" fontSize="small" />
                            : <ArrowUpwardIcon color="customColor" fontSize="small" />}
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

export default TaskFilter;
