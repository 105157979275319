import React, { useEffect } from 'react'
import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import toast from 'react-hot-toast';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import secureApiFetch from '../../services/secureFetchApi';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TableLoader from '../../ui/TableLoader';
import { IconButton } from '@mui/material';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '25px',
    borderWidth: '2px',
    borderRadius: '5px',
    borderColor: 'gray',
    borderStyle: 'dashed',
    backgroundColor: '#fcfcfc',
    color: 'black',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: 'yellow'
};

const acceptStyle = {
    borderColor: 'green'
};

const rejectStyle = {
    borderColor: 'red'
};

const AttachTab = ({ taskId, parentType = 'task' }) => {
    const [attachmentData, setAttachmentData] = useState([])
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const [loading, setLoading] = useState(false)
    const onFileDrop = (newFiles) => setAcceptedFiles(newFiles)

    const onUploadButtonClick = ev => {
        const formData = new FormData();
        formData.append('parent_type', parentType);
        acceptedFiles.forEach(file => {
            formData.append('file', file);
        })
        let uri = `/api/v1/attachment?parent_id=${taskId}`;
        secureApiFetch(uri, {
            method: 'POST',
            body: formData
        })
            .then((res) => {
                getAttachmentData()
                if (res.status) {
                    toast.success('Attachment File uploaded Successfully')
                } else {
                    toast.error('Attachment File Not Uploaded')
                }
                setAcceptedFiles([]);
            })
            .catch(err => console.error(err));
    }

    const handleDelete = (event, id) => {
        event.preventDefault()
        const confirmDelete = window.confirm('Are you sure you want to delete this attachment?');
        if (confirmDelete) {
            secureApiFetch(`/api/v1/attachment/${id}`, {
                method: 'Delete',
            })
                .then((res) => {
                    getAttachmentData()
                    if (res.status === 200) {
                        toast.success('Attachment Deleted Successfully')
                    } else {
                        toast.error('An error occurred while Deleting the Attachment')
                    }
                })
                .catch(err => console.error(err));
        }
    }

    const handleDownload = (event, id) => {
        event.preventDefault()
        secureApiFetch(`/api/v1/attachment/download/${id}`, {
            method: 'GET',
            header: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((data) => {
                const downloadUrl = data.download_url
                window.open(downloadUrl, '_blank')
            })
            .catch(err => console.error(err));
    }

    const getAttachmentData = () => {
        setLoading(true)
        let uri = `/api/v1/attachment/${taskId}?parent_type=task&page=1`;
        secureApiFetch(uri, {
            method: 'GET',
            header: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                setAttachmentData(data)
                setLoading(false)
            })
            .catch(err => console.error(err));
    }

    useEffect(() => {
        getAttachmentData()
    }, [])

    const removeFile = (file) => setAcceptedFiles(acceptedFiles.filter(f => f !== file))
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop: onFileDrop });
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    return (
        <div className='flex space-x-4 w-full'>
            <div className="w-[30%]">
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <div>
                        <BackupOutlinedIcon sx={{ fontSize: 60 }} className='text-[#3A3D89]' />
                    </div>
                    <p>Upload Attachment File here</p>
                </div>

                <div className='flex flex-col items-start my-2'>
                    <div>
                        {acceptedFiles.length === 0 && <label className='text-base'>Attachment List Empty</label>}
                        {acceptedFiles.length > 0 &&
                            <ul className='list-disc px-5' spacing={3}>{acceptedFiles.map(file => (
                                <li key={file.path}> {file.path} - {file.size} bytes file  <button onClick={() => removeFile(file)} className='bg-red-600 text-white text-sm px-2 py-1 rounded'>Cancel</button> </li>
                            ))}
                            </ul>
                        }
                    </div>
                    <button onClick={onUploadButtonClick} disabled={acceptedFiles.length === 0} className='bg-[#3A3D89] mt-1 px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                        Upload Attachment
                    </button>
                </div>
            </div>
            <div className="w-[70%]">
                <Paper sx={{ width: '100%' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead className='bg-[#3D3A89] text-white'>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="center">Id</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">File Name</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="center">Type</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="center">Actions</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading && (<TableRow>
                                    <TableCell colSpan={10}><TableLoader /></TableCell>
                                </TableRow>)}
                                {!loading && attachmentData.length === 0 && (
                                    <tr>
                                        <td colSpan={10} align="center " className='py-3 font-medium text-lg text-red-600'>
                                            No data available
                                        </td>
                                    </tr>
                                )}
                                {!loading && attachmentData.length > 0 && attachmentData.length !== 0 && attachmentData.map((item, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                        <TableCell align="center">{item.attachment_id}</TableCell>
                                        <TableCell align="left">
                                            <span onClick={(event) => handleDownload(event, item.attachment_id)}>
                                                {item.client_file_name}
                                            </span>
                                            <IconButton onClick={() => { navigator.clipboard.writeText(item.client_file_name) }}>
                                                <ContentCopyIcon className='text-[#3A3D89]' fontSize='small'/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">{item.file_mimetype}</TableCell>
                                        <TableCell align="center">
                                            <IconButton onClick={(event) => handleDelete(event, item.attachment_id)}>
                                                <DeleteIcon className='text-red-600' fontSize='small' />
                                            </IconButton>
                                            <IconButton onClick={(event) => handleDownload(event, item.attachment_id)} >
                                                <FileDownloadOutlinedIcon className='text-[#3A3D89]' fontSize='small' />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        </div >
    )
}

export default AttachTab