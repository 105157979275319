import React, { useEffect, useState } from 'react'
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Box } from '@mui/material';
import { Cron } from 'react-js-cron'
import { Toaster, toast } from 'react-hot-toast';
import CustomBreadcrumbs from '../../../ui/BreadCrumbs';
import Folder from '../../../ui/Folder';
import { useNavigate, useParams } from 'react-router-dom';
import secureApiFetch from '../../../services/secureFetchApi';
import { useSelectedWorkspace } from '../../context/SelectedWorkspaceContext ';
import OutlinedInput from '@mui/material/OutlinedInput';
import 'react-js-cron/dist/styles.css'
import Slider from '@mui/material/Slider';
import ReactQuill from 'react-quill';

const EditSchedule = () => {
    const { selectedWorkspaceObject } = useSelectedWorkspace()
    const navigate = useNavigate()
    const { id } = useParams()
    const [showCron, setShowCron] = useState(false)
    const [workspaceUsers, setWorkspaceUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        "title": "",
        "description": "",
        "repetition": "",
        "status": "on_hold",
        "assignee_id": 0,
        "task_type": "",
        "task_priority": 0,
        "task_importance": ''
    })
    const [cronValue, setCronValue] = useState('')

    const getData = () => {
        secureApiFetch(`/api/v1/schedule_task/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setFormData(data)
                setCronValue(data.repetition)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const fetchWorkspaceUsers = async () => {
        try {
            if (formData.workspace_id || selectedWorkspaceObject.workspace_id) {
                setLoading(true)
                const userResponse = await secureApiFetch(`/api/v1/workspace_permissions/${formData.workspace_id || selectedWorkspaceObject.workspace_id}/users`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }
                    }
                )
                const data = await userResponse.json();
                setWorkspaceUsers(data)
            }
        } catch (error) {
            console.error('Error fetching WorkspaceUsers:', error);
        } finally {
            setLoading(false)
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const dataMap = {
            "title": formData.title,
            "description": formData.description,
            "repetition": formData.repetition,
            "status": formData.status,
            "assignee_id": formData.assignee_id,
            "task_type": formData.task_type,
            "task_priority": formData.task_priority,
            "task_importance": formData.task_importance,
            "repetition": cronValue
        }
        secureApiFetch(`/api/v1/schedule_task/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(dataMap)
        })
            .then(response => {
                if (response.ok) {
                    toast.success('Schedule updated successfully');
                    setTimeout(() => {
                        navigate('/scheduletask')
                    }, 1500);
                } else {
                    toast.error('Failed to update schedule');
                }
            })
            .catch(error => {
                console.error('Error updating schedule:', error);
                toast.error('An error occurred while updating the schedule');
            });
    }

    useEffect(() => {
        getData()
    }, [])
    useEffect(() => {
        fetchWorkspaceUsers()
    }, [formData.workspace_id, selectedWorkspaceObject])

    const marks = [
        { value: 0, label: 'High 0' },
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5 Low' },
    ];
    const getSliderColor = (value) => {
        if (value === 0) return '#FF0000 ';
        if (value === 1) return '#FF6347';
        if (value === 2) return '#FFA07A ';
        if (value === 3) return '#94C973 ';
        if (value === 4) return '#006400 ';
        if (value === 5) return '#556B2F';
        return 'default';
    };

    return (
        <div>
            <div className='flex flex-col pb-3'>
                <CustomBreadcrumbs backUrl="/" backLabel="Schedule" />
                <Folder label='Edit Schedule' />
            </div>
            <hr />
            <div className='mt-4'>
                <form>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div className="space-y-4">
                            <TextField
                                fullWidth
                                label="Title"
                                variant="outlined"
                                value={formData.title}
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                            <TextField
                                fullWidth
                                label="Task Type"
                                variant="outlined"
                                value={formData.task_type}
                                onChange={(e) => setFormData({ ...formData, task_type: e.target.value })}
                                required
                            />
                            <ReactQuill
                                theme="snow"
                                name="description"
                                value={formData.description}
                                onChange={(value) => setFormData({ ...formData, description: value })}
                                className='h-40'
                            />
                        </div>
                        <div className="space-y-4">
                            <div className="grid grid-cols-2 gap-4">
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        value={formData.status}
                                        onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                                        label="Status"
                                    >
                                        <MenuItem value="on_hold">On Hold</MenuItem>
                                        <MenuItem value="dependent_hold">Dependent Hold</MenuItem>
                                        <MenuItem value="todo">To Do</MenuItem>
                                        <MenuItem value="inprogress">In Progress</MenuItem>
                                        <MenuItem value="completed">Completed</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Task Importance</InputLabel>
                                    <Select
                                        value={formData.task_importance}
                                        onChange={(e) => setFormData({ ...formData, task_importance: e.target.value })}
                                        label="Task Importance"
                                        required
                                    >
                                        <MenuItem value="do_first">Do First</MenuItem>
                                        <MenuItem value="schedule">Schedule</MenuItem>
                                        <MenuItem value="delegate">Delegate</MenuItem>
                                        <MenuItem value="eliminate">Eliminate</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="grid grid-cols-2 gap-4">


                                <Box sx={{ width: 200, marginLeft: 2 }}>
                                    Task Priority
                                    <Slider
                                        name="task_priority"
                                        aria-label="Task Priority"
                                        value={formData.task_priority}
                                        step={1}
                                        onChange={(e) => setFormData({ ...formData, task_priority: e.target.value })}
                                        min={0}
                                        max={5}
                                        marks={marks}
                                        valueLabelDisplay="off"
                                        color="customColor"
                                        label={true}
                                        sx={{
                                            '& .MuiSlider-thumb': {
                                                color: getSliderColor(formData.task_priority),
                                            },
                                            '& .MuiSlider-track': {
                                                color: getSliderColor(formData.task_priority),
                                            },
                                            '& .MuiSlider-rail': {
                                                color: getSliderColor(formData.task_priority),
                                            },
                                        }}
                                    />
                                </Box>
                                <FormControl fullWidth>
                                    <InputLabel>Assign To</InputLabel>
                                    <Select
                                        name="assignee_id"
                                        value={formData.assignee_id}
                                        onChange={(e) => setFormData({ ...formData, assignee_id: e.target.value })}
                                        input={<OutlinedInput label="Assign To" />}
                                        required
                                    >
                                        {workspaceUsers.map(userData => (
                                            <MenuItem key={userData.id} value={userData.user_id}>
                                                {userData.username}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                Please Set repetition
                                <Cron value={cronValue} setValue={setCronValue} />
                            </div>
                            <TextField
                                fullWidth
                                label="Repetition"
                                variant="outlined"
                                multiline
                                value={cronValue}
                            />
                        </div>
                    </div>
                    <div className="fixed bottom-6 right-10">
                        <Button variant="contained" color="customColor" sx={{ marginTop: 2 }} onClick={handleSubmit} >
                            Update Schedule
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditSchedule;