import React from 'react';
import CustomBreadcrumbs from '../../ui/BreadCrumbs';
import Folder from '../../ui/Folder';

const Settings = () => {

  return (
    <div>
      <div className='flex flex-col '>
        <CustomBreadcrumbs backUrl="/" backLabel="Settings" />
      </div>
      <div className="flex items-center">
        <Folder label={'Settings'} />
      </div>
      <hr className='mb-2' />
      <div>
      </div>

    </div>
  )
};

export default Settings;
