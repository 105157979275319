import React from 'react'

const ColorBadge = ({ badge }) => {
    let badgeColorClass = '';
    switch (badge) {
        // status
        case 'planned':
            badgeColorClass = 'bg-[#d49069] text-white';
            break;
        case 'on_hold':
            badgeColorClass = 'bg-[#FF0000] text-white';
            break;
        case 'dependent_hold':
            badgeColorClass = 'bg-red-200 text-red-800';
            break;
        case 'todo':
            badgeColorClass = 'bg-[#FFC000] text-white';
            break;
        case 'inprogress':
            badgeColorClass = 'bg-[#00B050] text-white';
            break;
        case 'completed':
            badgeColorClass = 'bg-[#0070C0] text-white';
            break;
        case 'Archive':
            badgeColorClass = 'bg-[#FF0000] text-white';
            break;

        // user role
        case 'admin':
            badgeColorClass = 'bg-red-100 text-red-800';
            break;
        case 'user':
            badgeColorClass = 'bg-blue-100 text-blue-800';
            break;

        default:
            badgeColorClass = 'bg-gray-100 text-gray-800';
    }
    const badgeContent = badge ? badge : "-";
    return (
        <div>
            <span className={`text-xs me-2 px-2 py-1 font-medium rounded ${badgeColorClass}`}>
                {badgeContent}
            </span>
        </div>
    );
};

export default ColorBadge