import * as React from 'react';
import { toast, Toaster } from 'react-hot-toast'
import { Link, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { useSelectedWorkspace } from '../context/SelectedWorkspaceContext ';
import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import Slide from '@mui/material/Slide';
import DateFormat from '../../ui/DateFormat';
import secureApiFetch from '../../services/secureFetchApi';
import TableLoader from '../../ui/TableLoader';
import ColorBadge from '../../ui/ColorBadge';
import TaskFilter from '../../features/TaskFilter';

export default function TaskData() {
    const [taskData, setTaskData] = React.useState([]);
    const [isDeleted, setIsDeleted] = React.useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { selectedWorkspaceObject } = useSelectedWorkspace();
    const [loading, setLoading] = useState(false)
    const [assigneeNames, setAssigneeNames] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const getData = () => {
        setLoading(true)
        let url = `/api/v1/task/workspace/${selectedWorkspaceObject.workspace_id}?${queryParams.toString()}`
        secureApiFetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then((res) => {
                const totalPagesCount = parseInt(res.headers.get('X-Page-Count'));
                setTotalPages(totalPagesCount);
                return res.json();
            })
            .then((data) => {
                setTaskData(data);
                setLoading(false)
            })
    }
    const getUserData = () => {
        setLoading(true)
        let url = `/api/v1/workspace_permissions/${selectedWorkspaceObject.workspace_id}/users`
        secureApiFetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                const names = data.map(task => ({
                    'assignee_id': task.user_id,
                    'assignee_name': task.username
                }));
                const uniqueNamesSet = new Set(names.map(JSON.stringify));
                const uniqueNames = Array.from(uniqueNamesSet).map(JSON.parse);
                setAssigneeNames(uniqueNames);
                console.log(uniqueNames);
                setLoading(false)
            })
    }

    const handleDelete = (taskId) => {
        const confirm = window.confirm('Are you sure you want to delete this record?');
        if (confirm) {
            if (taskId) {
                secureApiFetch(`/api/v1/task/${taskId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                    .then((res) => {
                        if (res.ok) {
                            getData()
                            toast.success('The task has been deleted successfully');
                        } else {
                            throw new Error('Failed to delete task');
                        }
                    })
                    .catch((error) => {
                        toast.error('Error deleting task: ' + error);
                    })
                    .finally(() => {
                        setIsDeleted(!isDeleted);
                    });
            }
        }
    };

    useEffect(() => {
        if (selectedWorkspaceObject.workspace_id) {
            getData();
            getUserData();
        }

    }, [selectedWorkspaceObject]);

    return (
        <div className='w-full'>
            <Toaster position='top-center' reverseOrder={false} />
            <hr className='mb-2' />
            <TaskFilter
                workId={selectedWorkspaceObject.workspace_id}
                assigneeNames={assigneeNames}
                setTaskData={setTaskData}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                page={currentPage}
                setCurrentPage={setCurrentPage}
                setLoading={setLoading}
            />
            <hr className='my-2' />
            <Paper sx={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead className='bg-[#3D3A89] text-white'>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">id</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Title</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Status</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Type</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Start Date</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Deadline</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="left">Dependent Task Title</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="center">Assignee</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading && (<TableRow>
                                <TableCell colSpan={10}><TableLoader /></TableCell>
                            </TableRow>)}
                            {!loading && taskData.length === 0 && (
                                <tr>
                                    <td colSpan={10} align="center " className='py-3 font-bold text-xl text-red-600'>
                                        No data available
                                    </td>
                                </tr>
                            )}
                            {!loading && taskData.length > 0 && taskData.length !== 0 && taskData.map((item, index) => (
                                <TableRow key={index} className={`${item.is_archived === true ? ' text-red-700' : ''}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                    <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                        {item.task_id}
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                        <Link className={`${item.is_archived === true ? 'text-red-700 line-through' : ''}`} to={`/tasks/detail/${item.task_id}`}>
                                            {item.title}
                                        </Link>
                                    </TableCell>
                                    <TableCell align="left">
                                        <ColorBadge badge={item.status} /> </TableCell>
                                    <TableCell align="left">{item.task_type}</TableCell>
                                    <TableCell align="left">
                                        <DateFormat date={item.start_date} />
                                    </TableCell>
                                    <TableCell align="left">
                                        <DateFormat date={item.end_date} />
                                    </TableCell>
                                    <TableCell align="left">{item.dependent_task_title}</TableCell>
                                    <TableCell align="center">{item.assignee_name}</TableCell>
                                    <TableCell align="center">
                                        <IconButton onClick={() => handleDelete(item.task_id)} aria-label="delete">
                                            <DeleteIcon className='text-red-700' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}
