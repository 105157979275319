import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material';
import { useSelectedWorkspace } from '../../context/SelectedWorkspaceContext ';
import secureApiFetch from '../../../services/secureFetchApi'
import Chart from "react-apexcharts";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';


const WorkloadbyDay = () => {
  const { selectedWorkspaceObject } = useSelectedWorkspace();
  const [taskData, setTaskData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const formatDate = (date) => date.toISOString().split('T')[0];

  const getTask = (from_date, to_date) => {
    const url = `/api/v1/task/filter_by/start_dates/workspace/${selectedWorkspaceObject.workspace_id}?from_date=${from_date}&to_date=${to_date}`;
    secureApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((resp) => resp.json())
      .then((data) => {
        setTaskData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log('Error Fetching Data', error);
      });
  };

  const handleChange = (e) => {
    const valueData = parseInt(e.target.value, 10);
    const todayDate = new Date();
    const futureDate = new Date(todayDate);
    futureDate.setDate(todayDate.getDate() + valueData);
    getTask(formatDate(todayDate), formatDate(futureDate));
  };

  useEffect(() => {
    if (selectedWorkspaceObject) {
      const todayDate = new Date();
      const futureDate = new Date(todayDate);
      futureDate.setDate(todayDate.getDate() + 90);
      getTask(formatDate(todayDate), formatDate(futureDate));
    }
  }, [selectedWorkspaceObject]);

  const handleSubmit = () => {
    if (startDate && endDate) {
      getTask(startDate, endDate);
    }
    handleClose();
  };

  const prepareChartData = () => {
    if (taskData.length > 0) {
      const groupedTasks = taskData.reduce((acc, task) => {
        const assigneeName = task.assignee_name;
        const date = task.start_date.split('T')[0];
        if (!acc[assigneeName]) {
          acc[assigneeName] = {};
        }
        if (!acc[assigneeName][date]) {
          acc[assigneeName][date] = 0;
        }
        acc[assigneeName][date]++;
        return acc;
      }, {});

      const allTasks = taskData.reduce((acc, task) => {
        const date = task.start_date.split('T')[0];
        if (!acc[date]) {
          acc[date] = 0;
        }
        acc[date]++;
        return acc;
      }, {});

      const assignees = Object.keys(groupedTasks);

      const labels = Array.from(
        new Set(taskData.map((task) => task.start_date.split('T')[0]))
      ).sort();

      const series = assignees.map((assignee) => {
        return {
          name: assignee,
          data: labels.map((label) => groupedTasks[assignee][label] || 0)
        };
      });

      series.push({
        name: 'All Tasks',
        data: labels.map((label) => allTasks[label] || 0),
      });

      return { labels, series };
    }
    return { labels: [], series: [] };
  };

  const { labels, series } = prepareChartData();
  const options = {
    chart: {
      id: 'task-date-chart',
      toolbar: {
        show: false
      }
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      categories: labels,
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
      },
    },
    colors: ['#775DD0', '#FF4560', '#3A3D89', '#00E396', '#FEB019', '#FF69B4'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100, 120]
      }
    }
  };

  return (
    <div>
      {taskData.length > 0 && (
        <div className='flex items-center justify-end space-x-2'>
          <FormControl sx={{ minWidth: 200, fontSize: '10px' }} size="small">
            <InputLabel id="demo-select-small-label">Select Days Range</InputLabel>
            <Select labelId="demo-select-small-label" id="demo-select-small" label="Select Days Range" onChange={handleChange}>
              <MenuItem value={7}>Next 7 Days</MenuItem>
              <MenuItem value={30}>Next 30 Days</MenuItem>
              <MenuItem value={90}>Next 90 Days</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={handleClickOpen} variant='contained' color='customColor' size='small'>
            Select Custom Date Range
          </Button>
        </div>
      )}
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : taskData.length > 0 ? (
          <Chart options={options} series={series} type="area" width="100%" height="300" />
        ) : (
          <p>No data available</p>
        )}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Select Custom Date Range"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
            <TextField
              size='small'
              label="Start Date"
              variant="outlined"
              margin="normal"
              value={startDate}
              onChange={(event) => setStartDate(event.target.value)}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
            <TextField
              size='small'
              label="End Date"
              variant="outlined"
              margin="normal"
              value={endDate}
              onChange={(event) => setEndDate(event.target.value)}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} variant='contained' color='customColor' autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WorkloadbyDay;

