// Settings.js
import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import TaskData from './table';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import CustomBreadcrumbs from '../../ui/BreadCrumbs';
import Folder from '../../ui/Folder';

const TaskList = () => {
  const navigate = useNavigate()

  const handleCreate = () => {
    navigate('/tasks/create')
  }

  return (
    <div>
      <CustomBreadcrumbs backUrl="/" backLabel="Task" />
      <div className='flex items-center justify-between'>
        <Folder label={'Task'} />
        <Button variant="contained" color="customColor" onClick={handleCreate} endIcon={<PlaylistAddIcon />}>Add Task</Button>
      </div>
      <div className=''>
        <TaskData />
      </div>

    </div>
  )
};

export default TaskList;
